import {
  getTcGridDetailsDialogId,
  getTcGridFilters,
} from './../selectors/tc-grid-selectors';
import { TcSpinnerService } from '@tc/store';
import { deleteItem, TcDataService } from '@tc/data-store';
import {
  LoadTcGridDataPayload,
  LoadTcGridMoreDataPayload,
  OpenTcGridDetailsPopupPayload,
  UpdateTcGridRowPayload,
  RefreshTcGridDataPayload,
  InitTcGridPayload,
  openTcGridConfirmPopupPayload,
} from './../actions/tc-grid-payload';
import {
  loadTcGridData,
  loadTcGridDataSuccess,
  loadTcGridMoreData,
  loadTcGridMoreDataSuccess,
  openTcGridDetailsPopup,
  updateTcGridRow,
  updateTcGridRowSuccess,
  refreshTcGridData,
  refreshTcGridDataSuccess,
  initTcGridStore,
  setTcGridDetailsDialogId,
  closeTcGridDetailsDialog,
  closeTcGridDetailsDialogSuccess,
  openTcGridConfirmPopup,
} from './../actions/tc-grid-actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, first, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TcComponentLookupRegistry } from '../../../decorators/tc-component-lookup';
import {
  getTcGridDataProvider,
  getTcGridSkip,
  getTcGridSort,
  getTcGridTake,
} from '../selectors/tc-grid-selectors';
import {
  DEFAULT_TC_GRID_STATE_KEY,
  NgRxTcGridState,
} from '../state/tc-grid-state';
import { hasValue } from '@tc/utils';
import { Observable } from 'rxjs';
import { selectValueByKey } from '@tc/store';
import { BaseCallbackTcStorePayload } from '@tc/abstract';
import { TcPromptDialogComponent } from '@tc/dialog';
import { ConfigService } from 'apps/frontend/src/app/shared/services/config.service';
import { getTcSmartFormIsChangedState } from '@tc/smart-form';

@Injectable()
export class TcGridEffects {
  gridStore$: Observable<NgRxTcGridState>;

  /**
   * Constructor
   */
  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly gridDataService: TcDataService,
    private readonly spinner: TcSpinnerService,
    private readonly configService: ConfigService
  ) {
    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * Load tc grid data effect
   */
  loadTcGridData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcGridData),
        tap(async (payload: LoadTcGridDataPayload) => {
          const { storeKey } = payload;

          const action = `${payload.storeKey} - spinner`;

          this.spinner.showSpinner(action);

          const dataProvider = await this.gridStore$
            .pipe(select(getTcGridDataProvider, { storeKey }), take(1))
            .toPromise();
          const pageSize = await this.gridStore$
            .pipe(select(getTcGridTake, { storeKey }), take(1))
            .toPromise();
          const storeFilter = await this.gridStore$
            .pipe(select(getTcGridFilters, { storeKey }), take(1))
            .toPromise();

          const { skip } = payload;

          const service = this.gridDataService.getService<any>(
            payload.storeKey,
            dataProvider
          );
          const result = await service.getData(skip, pageSize, storeFilter);

          const { data, total } = result;

          this.store$.dispatch(
            loadTcGridDataSuccess({
              storeKey: payload.storeKey,
              data,
              total,
              take: pageSize,
              skip,
            })
          );

          this.spinner.hideSpinner(action);
        })
      ),
    { dispatch: false }
  );

  /**
   * Load tc grid more data effect
   */
  loadTcGridMoreData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcGridMoreData),
        tap(async (payload: LoadTcGridMoreDataPayload) => {
          const { storeKey } = payload;

          const action = `${payload.storeKey} - spinner`;

          this.spinner.showSpinner(action);

          const dataProvider = await selectValueByKey(
            getTcGridDataProvider,
            this.gridStore$,
            storeKey
          ); // this.gridStore$.pipe(select(getTcGridDataProvider, { storeKey }), take(1)).toPromise();
          const pageSize = await selectValueByKey(
            getTcGridTake,
            this.gridStore$,
            storeKey
          ); // this.gridStore$.pipe(select(getTcGridTake, {storeKey }), take(1)).toPromise();
          const storeFilter = await this.gridStore$
            .pipe(select(getTcGridFilters, { storeKey }), take(1))
            .toPromise();

          const { skip } = payload;

          const service = this.gridDataService.getService<any>(
            payload.storeKey,
            dataProvider
          );
          const result = await service.getData(skip, pageSize, storeFilter);

          const { data, total } = result;

          this.store$.dispatch(
            loadTcGridMoreDataSuccess({
              storeKey: payload.storeKey,
              data,
              total,
              take: pageSize,
              skip,
            })
          );

          this.spinner.hideSpinner(action);
        })
      ),
    { dispatch: false }
  );

  /**
   * Update tc grid row effect
   */
  updateTcGridRow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTcGridRow),
        tap(async (payload: UpdateTcGridRowPayload) => {
          this.store$.dispatch(
            updateTcGridRowSuccess({
              storeKey: payload.storeKey,
              rowData: payload.rowData,
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Open tc grid details popup effect
   */
  openTcGridDetailsPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openTcGridDetailsPopup),
        tap((payload: OpenTcGridDetailsPopupPayload) => {
          const { storeKey, detailsPopupComponent, data, width, maxWidth, height } =
            payload;

          const component = TcComponentLookupRegistry.get(
            detailsPopupComponent
          );
          const ref = this.dialog.open(component, {
            data: {
              entityData: data,
              formStoreKey: storeKey,
            },
            width: width ?? '65%',
            maxWidth: maxWidth ?? '80vw',
            height: height ?? '80%',
            panelClass: 'tc-grid-detail-popup',
          });

          if (this.configService.get('showDetailConfirmationDialog')) {
            let originalClose = ref.close;

            ref.close = async (...data) => {
              const isChanged = await this.store$
                .select(getTcSmartFormIsChangedState(storeKey))
                .pipe(first())
                .toPromise();

              if (isChanged) {
                const isConfirmed = await this.confirmClosing();

                if (isConfirmed) {
                  originalClose.apply(ref, ...data);
                }
              } else {
                originalClose.apply(ref, ...data);
              }
            };
          }

          this.store$.dispatch(
            setTcGridDetailsDialogId({
              storeKey,
              dialogId: ref.id,
            })
          );
        })
      ),
    { dispatch: false }
  );

  private async confirmClosing(): Promise<boolean> {
    return await this.dialog
      .open(TcPromptDialogComponent, {
        width: '33rem',
        data: {
          title: 'globalLabels.warning',
          text: 'unsaved-data-confirmation',
        },
      })
      .afterClosed()
      .pipe(first())
      .toPromise();
  }

  /**
   * Open tc grid confirm popup effect
   */
  openTcGridConfirmPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openTcGridConfirmPopup),
        tap((payload: openTcGridConfirmPopupPayload) => {
          const {
            storeKey,
            detailsPopupComponent,
            data,
            width,
            height,
            callbackAction,
            actionPayload,
          } = payload;

          const component = TcComponentLookupRegistry.get(
            detailsPopupComponent
          );
          const ref = this.dialog.open(component, {
            data: {
              title: `prompt.title`,
              text: `prompt.deletion-text`,
              entityData: data,
              formStoreKey: storeKey,
            },
            width: width ?? '65%',
            height: height ?? '80%',
          });

          this.store$.dispatch(
            setTcGridDetailsDialogId({
              storeKey,
              dialogId: ref.id,
            })
          );

          // If user confirmed action the callbackAction is dispatched
          ref.afterClosed().subscribe((result) => {
            if (result) {
              this.store$.dispatch(callbackAction(actionPayload));
            }
          });
        })
      ),
    { dispatch: false }
  );

  /**
   * Close tc grid details popup effect
   */
  closeTcGridDetailsDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeTcGridDetailsDialog),
        tap(async (payload: BaseCallbackTcStorePayload) => {
          const { storeKey } = payload;

          const dialogId = await selectValueByKey(
            getTcGridDetailsDialogId,
            this.gridStore$,
            storeKey
          );
          const dialogToClose = this.dialog.getDialogById(dialogId);
          dialogToClose.close();

          // Wait for the dialog to actually close. This ensures that, if we have a callback action,
          // it will only be dispatched after the dialog get gracefully closed.
          await dialogToClose.afterClosed().toPromise();

          // Dispatch the success action
          this.store$.dispatch(closeTcGridDetailsDialogSuccess(payload));
        })
      ),
    { dispatch: false }
  );

  /**
   * Close successful tc grid details popup effect.
   * Dispatches a callback action if it has one.
   */
  closeTcGridDetailsDialogSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeTcGridDetailsDialogSuccess),
        tap(async (payload: BaseCallbackTcStorePayload) => {
          const { callbackAction, callbackActionPayload } = payload;

          if (callbackAction) {
            this.store$.dispatch(callbackAction(callbackActionPayload));
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Refresh tc grid data effect
   */
  refreshTcGridData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTcGridData),
        tap(async (payload: RefreshTcGridDataPayload) => {
          // const gridStore$ = this.store$.pipe(select(DEFAULT_TC_GRID_STATE_KEY), filter(hasValue), distinctUntilChanged());

          const action = `${payload.storeKey} - spinner`;

          this.spinner.showSpinner(action);

          const { storeKey } = payload;

          const skip = await selectValueByKey(
            getTcGridSkip,
            this.gridStore$,
            storeKey
          ); //this.gridStore$.pipe(select(getTcGridSkip, {storeKey }), take(1)).toPromise();
          const sort = await selectValueByKey(
            getTcGridSort,
            this.gridStore$,
            storeKey
          ); // this.gridStore$.pipe(select(getTcGridSort, {storeKey }), take(1)).toPromise();

          this.store$.dispatch(
            refreshTcGridDataSuccess({
              storeKey: payload.storeKey,
              filter: payload.filter,
            })
          );

          this.store$.dispatch(
            loadTcGridData({
              storeKey,
              skip,
              sort,
            })
          );

          this.spinner.hideSpinner(action);
        })
      ),
    { dispatch: false }
  );
}
