import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SettingsState, State } from './settings.model';

export const selectSettingsState = createFeatureSelector< SettingsState>(
  'settings'
);

export const selectSettings = createSelector(
  selectSettingsState,
  (state: SettingsState) => state
);

export const selectSettingsTranslate = createSelector(
  selectSettings,
  (state: SettingsState) => state.translate
);

export const selectSettingsLanguage = createSelector(
  selectSettings,
  (state: SettingsState) => state.translate.language
);

export const selectSettingsLogger = createSelector(
  selectSettings,
  (state: SettingsState) => state.logger
);

export const selectSettingsNotifications = createSelector(
  selectSettings,
  (state: SettingsState) => state.notifications
);

export const selectTheme = createSelector(
  selectSettings,
  (settings) => settings.theme
);
