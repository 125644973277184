import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-received-delivery-notes-filter',
  templateUrl: './received-delivery-notes-filter.component.html',
  styleUrls: ['./received-delivery-notes-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReceivedDeliveryNotesFilterComponent
  extends TcFormComponent<any>
  implements OnInit
{
  gridStoreKey = 'received-delivery-notes-grid';

  constructor(translate: TcTranslateService, elem: ElementRef) {
    super(translate, elem);
  }
}
