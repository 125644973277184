export enum IndicatorKeys {
  VolumeDistributedHeader = 'volume-distributed-header',
  VolumeDistributed = 'volume-distributed',
  VolumeDistributedCategory2 = 'volume-distributed-category2',
  BeneficiariesHeader = 'beneficiaries-header',
  BeneficiariesRegisteredHouseholds = 'beneficiaries-registered-households',
  BeneficiariesRegisteredWithRights = 'beneficiaries-registered-with-rights',
  BeneficiariesPassages = 'beneficiaries-passages',
  BeneficiariesByAgeHeader = 'beneficiaries-by-age-header',
  BeneficiariesAge0to17 = 'beneficiaries-age-0to17',
  BeneficiariesAge18to29 = 'beneficiaries-age-18to29',
  BeneficiariesAge30to64 = 'beneficiaries-age-30to64',
  BeneficiariesAgeOver65Fse = 'beneficiaries-age-over65-fse',
  BeneficiariesAge0to3 = 'beneficiaries-age-0to3',
  BeneficiariesAge4to14 = 'beneficiaries-age-4to14',
  BeneficiariesAge15to25 = 'beneficiaries-age-15to25',
  BeneficiariesAge26to64 = 'beneficiairies-age-26to64',
  BeneficiariesAgeOver65 = 'beneficiaries-age-over65',
  BeneficiariesBySexHeader = 'beneficiaries-by-sex-header',
  BeneficiariesMale = 'beneficiaries-male',
  BeneficiariesFemale = 'beneficiaries-female',
  CommentHeader = 'comment-header',
  CommentValue = 'comment-value',
  InfoForModification = 'info-for-modification',
  AssociationDeviation = 'asso-deviation',
  BaDeviation = 'ba-deviation',
  LastReasonOfDeviation = 'last-reason-of-deviation',
  LastReasonOfDeviationBa = 'last-deviation-ba',
}
