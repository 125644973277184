import { TcSmartListConfig } from '@tc/abstract';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { OnInit } from '@angular/core';
import { initTcListDataStore } from '../tc-data-store/store';
import * as R from 'ramda';

/**
 * Base class for an advanced component with a list
 */
export class TcSmartListComponent extends TcSmartComponent implements OnInit {
  protected listConfig: TcSmartListConfig;
  protected store$: Store<any>;

  constructor(store$: Store<any>) {
    super(store$);
  }

  ngOnInit() {
    // Init tc list, common for grid and panel
    let clonedListConfig: TcSmartListConfig = R.clone(this.listConfig);

    const fieldsLevel1 = clonedListConfig.filterConfig?.fields;
    if (fieldsLevel1) {
      for (let fieldLevel1 of fieldsLevel1) {
        let fieldsGroupsLevel1 = fieldLevel1.fieldGroup;
        for (let fieldGroupLevel1 of fieldsGroupsLevel1) {
          let fieldGroupsLevel2 = fieldGroupLevel1.fieldGroup;
          if (fieldGroupsLevel2) {
            for (var fieldGroupLevel2 of fieldGroupsLevel2) {
              if (
                fieldGroupLevel2.props &&
                fieldGroupLevel2.props.clearButtonEnabled == undefined
              ) {
                fieldGroupLevel2.props.clearButtonEnabled = true;
              }
            }
          }
        }
      }
    }

    this.store$.dispatch(
      initTcListDataStore({
        storeKey: this.listConfig.storeKey,
        listConfig: clonedListConfig,
      })
    );
  }
}
