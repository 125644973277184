import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import {
  EntityCollectionService,
  EntityCollectionServiceFactory,
} from '@ngrx/data';
import { Subscription } from 'rxjs';
import { TcGenericEntity } from '../../../abstract/tc-generic-entity.interface';
import { TcList } from '../../../base/tc-list';
import { FilterTypesEnum } from '@tc/abstract';
import { TcFilterValue } from '../../../interfaces/tc-filter-value';
import { getTotalForEntity } from '@tc/store';
import { TcFilterTypes } from '@tc/abstract';
import { createObjectWithSubKeys } from '@tc/utils';
import { TcListComponent } from '../../dumb/tc-list/tc-list.component';
import { TcConfirmDialogComponent } from '../../tc-confirm-dialog/tc-confirm-dialog.component';

@Component({
  selector: 'tc-generic-list',
  templateUrl: './tc-generic-list.component.html',
  styleUrls: ['./tc-generic-list.component.scss'],
})
export class TcGenericListComponent<T>
  extends TcList
  implements OnInit, OnDestroy
{
  @Input()
  entityName: string;

  // @Input()
  // queryParams: QueryParams;

  service: EntityCollectionService<T>;

  deleteYesFocused = true;
  autoFocusDeleteDialog = false;

  tcList: TcListComponent;
  @ViewChild('tcList', { static: true }) set tcGenericList(
    values: TcListComponent
  ) {
    this.tcList = values;
  }

  entitiesSubscription: Subscription;
  totalSubscription: Subscription;

  deleteSuccessSubscription: Subscription;

  afterDeleteSuccess(row: TcGenericEntity) {}

  constructor(
    private store: Store<any>,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
    elem: ElementRef,
    private dialog: MatDialog
  ) {
    super(elem);
  }

  ngOnInit() {
    this.service = this.entityCollectionServiceFactory.create(this.entityName);

    // this.service.clearCache();

    // if (this.queryParams) {
    //   this.service.getWithQuery(this.queryParams);
    // } else {
    //   // this.service.getAll();
    //   this.service.getWithQuery({
    //     initial: 'true'
    //   });
    // }

    this.entitiesSubscription = this.service.entities$.subscribe((rows) => {
      this.rows = rows;

      this.tcList.rows = this.rows;
      this.tcList.filters = this.filters;
      this.tcList.dataSource.data = this.rows;
      this.tcList.totalCount = this.rows.length;

      if (this.rows.length > 0) {
        this.tcList.canLoadMoreResults = true;
      }

      if (
        this.tcList.totalItems === this.tcList.totalCount &&
        this.tcList.totalCount !== 0
      ) {
        this.tcList.calculateTotalLine();
      }
    });

    this.tcList.listName = this.listName;

    this.tcList.rows$ = this.rows$;

    this.tcList.columns = this.columns;

    this.tcList.showTotalCount = this.showTotalCount;
    this.tcList.hasActionsLabel = this.hasActionsLabel;
    this.tcList.isPaged = this.isPaged;
    this.tcList.pageSize = this.pageSize;
    this.tcList.pageSizeOptions = this.pageSizeOptions;
    this.tcList.pageShowFirstLastButtons = this.pageShowFirstLastButtons;

    this.tcList.rowActions = this.rowActions;
    this.tcList.onRowAction = this.onRowAction;

    this.tcList.bulkActions = this.bulkActions;
    this.tcList.onBulkAction = this.onBulkAction;

    this.tcList.onRowClick = this.onRowClick;

    this.tcList.onDeleteAction = (row: TcGenericEntity) => {
      const dialog = this.dialog.open(TcConfirmDialogComponent, {
        data: {
          title: this.listName + '.dialog.delete.title',
          message: this.listName + '.dialog.delete.message',
          noText: this.listName + '.dialog.delete.no',
          yesText: this.listName + '.dialog.delete.yes',
          yesFocused: this.deleteYesFocused,
        },
        autoFocus: this.autoFocusDeleteDialog,
        panelClass: this.listName + '-delete-dialog-panel',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result === 'yes') {
          this.deleteSuccessSubscription = this.service
            .delete(row.id)
            .subscribe((data) => this.afterDeleteSuccess(row));
        }
      });
    };

    this.tcList.selection = this.selection;

    this.tcList.allowDragAndDrop = this.allowDragAndDrop;
    this.tcList.dragStartDelay = this.dragStartDelay;
    this.tcList.dragAndDropCssClass = this.dragAndDropCssClass;
    this.tcList.afterDragAndDrop = this.afterDragAndDrop;

    this.tcList.hasFixedHeader = this.hasFixedHeader;

    // infinite scroll
    this.tcList.infiniteScrollDistance = this.infiniteScrollDistance;
    this.tcList.infiniteScrollUpDistance = this.infiniteScrollUpDistance;
    this.tcList.infiniteScrollThrottle = this.infiniteScrollThrottle;
    this.tcList.infiniteScrollWindow = this.infiniteScrollWindow;
    this.tcList.infiniteScrollDisabled = this.infiniteScrollDisabled;
    this.tcList.infiniteScrollHorizontal = this.infiniteScrollHorizontal;

    this.tcList.onScrollUp = this.onScrollUp;
    this.tcList.onScrollDown = this.onScrollDown;

    this.tcList.rowActionButtonsPosition = this.rowActionButtonsPosition;

    this.tcList.showTotalInActionsHeader = this.showTotalInActionsHeader;

    // sort
    this.tcList.sortStart = this.sortStart;
    this.tcList.sortType = this.sortType;
    this.tcList.sortActive = this.sortActive;
    this.tcList.sortDirection = this.sortDirection;

    this.tcList.applyServerSideSort = (sort: Sort) => {
      let sortBy = sort.active;

      const column = this.columns.find((c) => c.propertyName === sort.active);
      if (column && column.sortExpression) {
        sortBy = column.sortExpression;
      }

      this.service.clearCache();
      this.service.getWithQuery({
        orderBy: JSON.stringify({
          key: sortBy,
          order: sort.direction.toUpperCase(),
        }),
      });
    };

    this.tcList.filterType = this.filterType;
    this.tcList.applyClientSideFilter = this.applyClientSideFilter;
    this.tcList.applyServerSideFilter = () => this.refresh(true);

    this.tcList.hasAddButton = this.hasAddButton;

    this.tcList.addItemWhenKeyPresed = this.addItemWhenKeyPresed;
    this.tcList.keyForAddingItem = this.keyForAddingItem;

    this.tcList.addItem = this.addItem;
    this.tcList.onDuplicateRow = this.onDuplicateRow;

    this.tcList.hasTotalLine = this.hasTotalLine;
    this.tcList.totalLineColumns = this.totalLineColumns;
    this.tcList.totalDisplayColumn = this.totalDisplayColumn;
    this.tcList.totalCustomFunctions = this.totalCustomFunctions;

    this.totalSubscription = this.store
      .pipe(select(getTotalForEntity(this.entityName)))
      .subscribe((total) => {
        if (total) {
          this.tcList.totalItems = total;
        } else {
          this.tcList.totalItems = 0;
        }

        console.log('Total items: ', this.tcList.totalItems);

        if (
          this.tcList.totalItems === this.tcList.totalCount &&
          this.tcList.totalCount !== 0
        ) {
          this.tcList.calculateTotalLine();
        }
      });
  }

  public refresh(initial = false) {
    this.service.clearCache();
    this.service.getWithQuery({
      initial: initial && 'true',
      filter: JSON.stringify(
        this.getFilterValue(this.tcList.selectedFilterValues)
      ),
    });
  }

  private getFilterValue(filterValue: TcFilterValue) {
    let filter = {};
    const filterKeys = Object.keys(filterValue).filter(
      (key) =>
        ([
          TcFilterTypes.anyFieldContains,
          TcFilterTypes.anyFieldStartsWith,
        ].includes(key as TcFilterTypes) &&
          !!filterValue[key]) ||
        (Array.isArray(filterValue[key].value)
          ? filterValue[key].value.length
          : !!filterValue[key].value)
    );
    for (const key of filterKeys) {
      if (Array.isArray(filterValue[key].value)) {
        filter = {
          ...filter,
          ...createObjectWithSubKeys(key, {
            ...filterValue[key],
            value: [...new Set(filterValue[key].value)].toString(),
          }),
        };
        continue;
      }
      // is daterange
      if (filterValue[key].value?.format) {
        if (!filterValue[key].value?.start) {
          continue;
        }
        filter = {
          ...filter,
          ...createObjectWithSubKeys(key, {
            filterType: FilterTypesEnum.DateRange,
            value: `${filterValue[key].value.start.format(
              filterValue[key].value.format
            )}​​​​|${filterValue[key].value.end.format(
              filterValue[key].value.format
            )}​​​​`,
          }),
        };
        continue;
      }
      filter = {
        ...filter,
        ...createObjectWithSubKeys(key, filterValue[key]),
      };
    }
    return filter;
  }

  ngOnDestroy() {
    if (this.entitiesSubscription) {
      this.entitiesSubscription.unsubscribe();
    }

    if (this.totalSubscription) {
      this.totalSubscription.unsubscribe();
    }

    if (this.deleteSuccessSubscription) {
      this.deleteSuccessSubscription.unsubscribe();
    }
  }
}
