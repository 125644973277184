import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FilterTypesEnum, ListOrder, MaterialColor, TcConfigTypes, TcDataProviderType, TcSmartFilterConfig } from '@tc/abstract';
import { editTcGridButtonClicked, TcSmartGridComponent } from '@tc/advanced-components';
import { MaterialButtonType } from '@tc/buttons';
import { formlyColumn, formlyControl, formlyRow, TcFormlyComponent, TcFormlyWrapper, TcGridCellComponent, TcTranslateService } from '@tc/core';
import { loadTcData } from '@tc/data-store';
import { ITcRestApiRoutesProvider } from '@tc/rest-api';
import moment from 'moment';
import { DEFAULT_GRID_HEADER_HEIGHT, GRID_ROW_HEIGHT_SMALL } from '../../../../shared/utils/constants';
import { dateCellRenderer } from '../../../../shared/utils/format.utils';
import { getPreviousDay } from '../../../../shared/utils/getPreviousDate';

@Component({
  selector: 'app-delivery-notes-grid',
  templateUrl: './delivery-notes-grid.component.html',
  styleUrls: ['./delivery-notes-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeliveryNotesGridComponent extends TcSmartGridComponent implements OnInit {
  @ViewChild('calendarTrigger', { static: true })
  calendarTrigger: TemplateRef<any>;

  storeKey = 'delivery-notes-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  constructor(store$: Store<any>, private readonly routeProvider: ITcRestApiRoutesProvider, private readonly translateService: TcTranslateService) {
    super(store$);

    this.setFilterConfig();
  }

  ngOnInit() {
    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'delivery-notes-grid',
      gridOptions: {
        headerHeight: DEFAULT_GRID_HEADER_HEIGHT,
        rowHeight: GRID_ROW_HEIGHT_SMALL
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.RestApi,
        dataSet: this.routeProvider.getRoutes().bonsLivraison,
        fields: ''
      },
      columns: [
        {
          field: 'association.nom',
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          resizable: false
        },
        {
          field: 'banqueProvenance.nom',
          resizable: false
        },
        {
          field: 'numeroBL',
          //custom grid sort that keeps the backend order of the items
          comparator: (valueA, valueB, nodeA, nodeB, isDescending) => (isDescending ? -1 : 1),
          maxWidth: 90,
          minWidth: 90,
          suppressAutoSize: true,
          resizable: false
        },
        {
          field: 'dateLivraison',
          cellRenderer: params => dateCellRenderer(params?.value),
          minWidth: 110,
          maxWidth: 110,
          suppressAutoSize: true,
          resizable: false
        },
        {
          field: 'traite',
          cellRenderer: params => this.statusCellRenderer(params?.value),
          minWidth: 90,
          maxWidth: 90,
          suppressAutoSize: true,
          resizable: false
        },
        {
          field: 'actions',
          cellClass: 'actions-button',
          minWidth: 80,
          maxWidth: 80,
          suppressAutoSize: true,
          resizable: false,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Accent,
                faIcon: 'fa-eye',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'DeliveryNoteDetailsComponent'
                }
              }
            ]
          }
        }
      ],
      filterConfig: this.filterConfig
    };

    super.ngOnInit();
  }

  private statusCellRenderer(value: boolean): string {
    return value
      ? this.translateService.instant(`${this.storeKey}.filter.traite.values.traite`)
      : this.translateService.instant(`${this.storeKey}.filter.traite.values.non-traite`);
  }

  setFilterConfig() {
    this.fields = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'baId',
                type: TcFormlyComponent.TcSmartSelect,
                defaultValue: '',
                templateOptions: {
                  subscriptSizing: 'dynamic',
                  clearButtonEnabled: false,
                  filterType: FilterTypesEnum.Equal,
                  multiple: false,
                  labelFieldName: 'nom',
                  valueFieldName: 'id',
                  defaultValue: '',
                  defaultValueLabel: this.translateService.instant(`${this.storeKey}.filter.values.banqueId.allBanques`),
                  change: (field, event) => {
                    // If the value of the bank filter changes, we need to update the associations select
                    // with associations from the selected bank only
                    this.store$.dispatch(
                      loadTcData({
                        storeKey: 'delivery-notes-grid.filter-asId',
                        filter: {
                          filters: [
                            {
                              key: 'banqueId',
                              value: event.value
                            }
                          ]
                        }
                      })
                    );
                  },
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.RestApi,
                    dataSet: this.routeProvider.getRoutes().banques,
                    sortOrder: {
                      key: 'nom',
                      order: ListOrder.Asc
                    }
                  }
                },
                colSpan: 3
              }),
              formlyControl({
                key: 'asId',
                type: TcFormlyComponent.TcSmartSelect,
                defaultValue: '',
                // Update the "disabled" property based on the currently selected bank
                expressionProperties: {
                  'templateOptions.disabled': '!(!!model.baId)',
                  'model.asId': model => {
                    // If the user selects "Tous les banques", we need to automatically select "Tous les associations"
                    if (model.baId === '') {
                      return '';
                    }
                    return model.asId;
                  }
                },
                templateOptions: {
                  clearButtonEnabled: false,
                  filterType: FilterTypesEnum.Equal,
                  multiple: false,
                  labelFieldName: 'nom',
                  valueFieldName: 'id',
                  defaultValue: '',
                  defaultValueLabel: this.translateService.instant(`${this.storeKey}.filter.values.associationId.allAssociation`),
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.RestApi,
                    dataSet: this.routeProvider.getRoutes().associationsWithDeliveryNodes,
                    sortOrder: {
                      key: 'nom',
                      order: ListOrder.Asc
                    }
                  }
                },
                colSpan: 2
              }),
              formlyControl({
                key: 'nrBl',
                type: TcFormlyComponent.FormlyInput,
                colSpan: 1
              }),
              formlyControl({
                key: 'dateLivraison.start',
                type: TcFormlyComponent.TcDatePicker,
                colSpan: 2,
                defaultValue: moment(getPreviousDay(new Date())).toISOString()
              }),
              formlyControl({
                key: 'dateLivraison.end',
                type: TcFormlyComponent.TcDatePicker,
                colSpan: 2,
                defaultValue: moment(new Date()).toISOString()
              }),
              formlyControl({
                key: 'statut',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: '',
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: '',
                      label: this.translateService.instant(`${this.storeKey}.filter.traite.values.tous`)
                    },
                    {
                      value: 1,
                      label: this.translateService.instant(`${this.storeKey}.filter.traite.values.traite`)
                    },
                    {
                      value: 0,
                      label: this.translateService.instant(`${this.storeKey}.filter.traite.values.non-traite`)
                    }
                  ]
                },
                colSpan: 2
              })
            ]
          })
        ],
        colSpan: 12,
        wrappers: [TcFormlyWrapper.ExpansionPanel],
        templateOptions: {
          label: 'filter-options',
          displayWrapperOnlyOnMobile: true
        }
      })
    ];

    this.filterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      fields: this.fields,
      isPersistant: false
    };
  }
}
