import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';

import { filter, map } from 'rxjs/operators';
import { ShowSpinner, HideSpinner } from '../actions/spinner.actions';

@Injectable()
export class SpinnerEffects {
  constructor(private actions$: Actions) {}

  
  showSpinner$ = createEffect(() => this.actions$.pipe(
    filter((action: any) => (action && action.showSpinner ? action : null)),
    map((action: any) => new ShowSpinner())
  ));

  
  hideSpinner$ = createEffect(() => this.actions$.pipe(
    filter((action: any) => (action && action.hideSpinner ? action : null)),
    map((action: any) => new HideSpinner())
  ));
}
