/* eslint-disable */
import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  TcFormComponent,
  TcTranslateService,
  TcFormlyComponent,
} from '@tc/core';

@Component({
  selector: 'app-example-tc-textarea',
  templateUrl: './example-tc-textarea.component.html',
  styleUrls: ['./example-tc-textarea.component.scss'],
})
export class ExampleTcTextareaComponent extends TcFormComponent<any>
  implements OnInit {
  constructor(
    translate: TcTranslateService,
    elem: ElementRef,
    private dialogRef: MatDialogRef<ExampleTcTextareaComponent>
  ) {
    super(translate, elem);
  }

  ngOnInit() {
    // this.fields = [
    //   {
    //     key: 'textarea1',
    //     type: TcFormlyComponent.TcTextarea,
    //     templateOptions: {},
    //     expressionProperties: {
    //       'templateOptions.disabled': 'model.readOnly',
    //     },
    //   },
    //   {
    //     key: 'textarea2',
    //     type: TcFormlyComponent.TcTextarea,
    //     templateOptions: {
    //       required: true,
    //       config: {
    //         statusbar: false,
    //         menubar: false,
    //         toolbar: 'bold italic underline',
    //       },
    //     },
    //   },
    //   {
    //     key: 'textarea3',
    //     type: TcFormlyComponent.TcTextarea,
    //     templateOptions: {
    //       config: {
    //         statusbar: false,
    //         menubar: true,
    //         toolbar: 'italic',
    //       },
    //     },
    //     expressionProperties: {
    //       'templateOptions.disabled': 'model.readOnly',
    //     },
    //   },
    //   {
    //     key: 'textarea4',
    //     type: TcFormlyComponent.TcTextarea,
    //     templateOptions: {
    //       config: {
    //         statusbar: false,
    //         menubar: true,
    //         toolbar: 'bold',
    //       },
    //     },
    //     expressionProperties: {
    //       'templateOptions.disabled': 'model.readOnly',
    //     },
    //   },
    // ];
  }

  onClose() {
    this.dialogRef.close();
  }
}