import { TcGenericEntity } from '../../abstract/tc-generic-entity.interface';
import { TcForm } from '../../abstract/tc-form.interface';
import { OnInit, ElementRef, ViewChild, Directive } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcTranslateService } from '../../services/tc-translate.service';

@Directive()
export class TcFormComponent<T extends TcGenericEntity>
  implements OnInit, TcForm {
  model = {} as T;
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];

  smartFormId: string;

  constructor(public translate: TcTranslateService, public elem: ElementRef) {}

  @ViewChild('tcForm') tcForm: NgForm;

  ngOnInit(smartFormId?): void {
    this.smartFormId = smartFormId;

    if (this.elem.nativeElement) {
      this.findFormElement(this.elem.nativeElement);
    }
  }

  private findFormElement(elem: any) {
    const children = elem.children;
    for (const child of children) {
      if (child.tagName === 'FORM') {
        if (child.id !== '') {
          // Usecase for dumb form
          this.setLabels(child.id, this.fields);
          break;
        } else if (this.smartFormId) {
          // Usecase for smart form
          this.setLabels(this.smartFormId, this.fields);
          break;
        }
      }
      this.findFormElement(child);
    }
  }

  protected setLabels(prefix: string, fields: FormlyFieldConfig[]) {
    fields.forEach(f => {
      if (f.fieldArray) {
        this.setLabels(prefix, [f.fieldArray as FormlyFieldConfig]);
      }

      if (f.fieldGroup) {
        this.setLabels(prefix, f.fieldGroup);
      }

      if (f.key && typeof f.key === 'string') {
        if (!f.templateOptions) {
          f.templateOptions = {};
        }
        f.templateOptions.label = f.key;
        if (!f.expressionProperties) {
          f.expressionProperties = {};
        }

        f.expressionProperties = Object.assign(f.expressionProperties, {
          'templateOptions.label': this.translate.stream(
            prefix + '.labels.' + f.key
          ),
        });
      }
    });
  }
}
