import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TcCoreModule } from '@tc/core';
import { ITcRestApiRoutesProvider } from '@tc/rest-api';
import { AgGridModule } from 'ag-grid-angular';

import { LayoutModule } from '../modules/layout/layout.module';
import { CustomRoutingModule } from './custom-routing.module';
import { AssociationsModule } from './modules/associations/associations.module';
import { DeliveryNotesModule } from './modules/delivery-notes/delivery-notes.module';
import { HomeModule } from './modules/home/home.module';
import { IndicatorsModule } from './modules/indicators/indicators.module';
import { MenuModule } from './modules/menu/menu.module';
import { ProductReferentialModule } from './modules/product-referential/product-referential.module';
import { ReceivedDeliveryNotesModule } from './modules/received-delivery-notes/received-delivery-notes.module';
import { StatistiquesModule } from './modules/statistiques/statistiques.module';
import { AssociationsPageComponent } from './pages/associations-page/associations-page.component';
import { DeliveryNotePageComponent } from './pages/delivery-note-page/delivery-note-page.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { IndicatorsPageComponent } from './pages/indicators-page/indicators-page.component';
import { ProductReferentialPageComponent } from './pages/product-referential-page/product-referential-page.component';
import { ReceivedDeliveryNotesPageComponent } from './pages/received-delivery-notes-page/received-delivery-notes-page.component';
import { StatistiquesPageComponent } from './pages/statistuques-page/statistiques-page.component';
import { AmsRouteProvider } from './services/ams-route.provider';
import { SharedModule } from './shared/shared.module';

const modules = [
  MenuModule,
  AssociationsModule,
  HomeModule,
  SharedModule,
  ProductReferentialModule,
  RouterModule,
  IndicatorsModule,
  DeliveryNotesModule,
  ReceivedDeliveryNotesModule,
  StatistiquesModule,
];

const pages = [
  HomePageComponent,
  AssociationsPageComponent,
  ForbiddenPageComponent,
  ProductReferentialPageComponent,
  IndicatorsPageComponent,
  DeliveryNotePageComponent,
  ReceivedDeliveryNotesPageComponent,
  StatistiquesPageComponent,
];

const components = [...pages];

const services = [
  {
    provide: ITcRestApiRoutesProvider,
    useClass: AmsRouteProvider,
  },
];

@NgModule({
  declarations: [...components, ...pages],
  imports: [
    ...modules,
    CommonModule,
    TcCoreModule,
    CustomRoutingModule,
    LayoutModule,
    AgGridModule,
  ],
  exports: [...components],
  providers: [...services],
})
export class CustomModule {
}
