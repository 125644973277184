import { createAction, props } from '@ngrx/store';
import { TcFilterDef } from '@tc/abstract';
import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';

export const loadAssociationsStats = createAction(
  '[Associations] Load Associations Statistics Data',
  props<{ filter: TcFilterDef }>()
);

export const loadAssociationsCategoriesStatsSuccess = createAction(
  '[Association[ Load Associations Categories Statistics Success',
  props<{ payload: AmsStatsInterface[] }>()
);

export const loadAssociationsSoftwareStatsSuccess = createAction(
  '[Associations] Load Association Software Statistics Success',
  props<{ payload: AmsStatsInterface[] }>()
);

export const exportAssociationsListCsv = createAction(
  '[Associations] Export Associations List as CSV'
);

export const exportAssociationsListPdf = createAction(
  '[Associations] Export Associations List as Pdf'
);

export const setCancelEditing = createAction(
  '[Associations] Set Cancel editing flag',
  props<{ payload: boolean }>()
);

export const resetCancelEditing = createAction(
  '[Associations] Reset Cancel Editing flag'
);
