import { createEntityCacheSelector, EntityCache } from '@ngrx/data';
import { createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MemoizedSelectorWithProps, select } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import * as R from 'ramda';

const setSelectLabel = (item: any, valueName?: string): string => {
  if (item) {
    if (valueName) {
      const result = [];
      const values = valueName.split(',');
      values.forEach(value => {
        result.push(item[value]);
      });
      return result.join(' ');
    } else {
      return item.name ? item.name : '';
    }
  } else {
    return '';
  }
};

const getDistinctValues = (data: any[]): any[] => {
  const result = [];
  const map = new Map();
  for (const item of data) {
    if (!map.has(item.label)) {
      map.set(item.label, true);
      result.push(item);
    }
  }
  return result;
};

export const getSelectValuesForEntity = (
  entityName: string,
  keyName?: string,
  valueName?: string
) =>
  createSelector(createEntityCacheSelector(), (state: EntityCache) => {
    const data =
      state && state[entityName] && state[entityName].entities
        ? Object.values(state[entityName].entities).map(entity => ({
            value: keyName ? entity[keyName] : entity.id,
            label: setSelectLabel(entity, valueName),
          }))
        : [];

    return getDistinctValues(data);
  });

export const getTotalForEntity = (entityName: string) =>
  createSelector(createEntityCacheSelector(), (state: EntityCache) => {
    return state && state[entityName] && (state[entityName] as any).total
      ? (state[entityName] as any).total
      : null;
  });

/**
 * Generic selector
 * @param selector MemoizedSelectorWithProps
 */
export function selectByKey<T>(
  selector: MemoizedSelectorWithProps<T, { storeKey: string }, T>,
  store: any,
  storeKey: string
): Observable<T> {
  return store.pipe(select(selector, { storeKey }));
}

/**
 * Generic selector
 * @param selector MemoizedSelectorWithProps
 */
export function selectPropertyByKey<T>(
  selector: MemoizedSelectorWithProps<T, { storeKey: string }, T>,
  store: any,
  storeKey: string,
  propertyName: string
): Observable<T> {
  return store.pipe(
    select(selector, { storeKey }),
    map(value => R.propOr(null, propertyName)(value))
  );
}

/**
 *  Generic selector as promise
 */
export function selectValueByKey<T>(
  selector: MemoizedSelectorWithProps<T, { storeKey: string }, T>,
  store: any,
  storeKey: string
): Promise<T> {
  return store.pipe(select(selector, { storeKey }), take(1)).toPromise();
}
