import { createAction } from '@ngrx/store';
import { TcAction } from './tc-action';

export enum HttpErrorsActionTypes {
  AddHttpError = '[Error] Add HTTP error',
}

export class AddHttpError implements TcAction {
  readonly type = HttpErrorsActionTypes.AddHttpError;
  constructor(public payload: any) {}
}

export const Done = createAction('[Error] Done');


export type ErrorsActions = AddHttpError;
