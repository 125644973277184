import { Component } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent extends TcPage {
  constructor() {
    super();
  }
}
