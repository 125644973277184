import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { TcTranslateService } from '@tc/core';
import { getVersionDetails } from '../store/home.selectors';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { getAbilities } from '@tc/permissions';
import { take } from 'rxjs/operators';
import { PermissionAction } from '../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../typings/permission-subject.enum';

@Component({
  selector: 'app-home-details',
  templateUrl: './home-details.component.html',
  styleUrls: ['./home-details.component.scss'],
})
export class HomeDetailsComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  public version: string;
  public versionDate: string;
  public noOfAssociations: number;
  versionTitle: string;
  associationTitle: string;
  private homeSubscription: Subscription;
  isBA = false;

  constructor(
    store$: Store<any>,
    private readonly translate: TcTranslateService
  ) {
    super(store$);
  }

  async ngOnInit() {    
    this.versionTitle = this.translate.instant('home-details.version-title');
    this.associationTitle = this.translate.instant(
      'home-details.association-title'
    );

    this.homeSubscription = this.store$
      .select(getVersionDetails)
      .subscribe((versionDetails) => {
        const { version, date, associationsToBeValidated } = versionDetails;

        this.version = version;
        this.versionDate = moment(date).format('DD/MM/YYYY');
        this.noOfAssociations = associationsToBeValidated;
      });

      const abilities = await this.store$
        .select(getAbilities)
        .pipe(take(1))
        .toPromise();
      
      this.isBA = abilities.can(PermissionAction.ValidateAssociationsIndicators, PermissionSubject.All)
  }

  ngOnDestroy(): void {
    this.homeSubscription?.unsubscribe();
  }
}
