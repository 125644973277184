import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { TcService } from '@tc/abstract';
import { ConfigKeys } from '../../shared/interfaces/config.interface';
import { ConfigService } from '../../shared/services/config.service';
import { CustomEntitiesEnum } from '../../shared/typings/custom-entities.enum';
import { GenericRoutes } from '../../shared/typings/generic-routes';
import { CustomRequestOptions } from './crud.interface';

@Injectable({
  providedIn: 'root',
})
export class CrudService<
  TResult extends { id: number } = any
> extends TcService {
  protected basePath = 'http://localhost:3333/api';
  protected defaultHeaders = new HttpHeaders();

  constructor(
    private entityName: CustomEntitiesEnum,
    public httpClient: HttpClient,
    public config: ConfigService
  ) {
    super();

    this.basePath = config.get(ConfigKeys.API_BASE_PATH) || this.basePath;
  }

  public async delete(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<boolean> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .delete<any>(`${url}/${id}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise();
  }

  public async create(
    item: TResult,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .post<TResult>(`${url}`, item, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise();
  }

  public async update(
    item: TResult,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .put<TResult>(`${url}/${item.id}`, item, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise();
  }

  public async getById(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .get<TResult>(`${url}/${id}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise();
  }

  public async get(
    params: Params,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .get<TResult>(url, {
        params,
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise();
  }

  public async custom(request: CustomRequestOptions) {
    return this.httpClient
      .request(request.method, request.url, {
        withCredentials: true,
        observe: 'body',
        ...request.options,
      })
      .toPromise();
  }

  public getEntityServiceEndpoint() {
    switch (this.entityName) {
      case CustomEntitiesEnum.Auth:
        return `${this.basePath}/${GenericRoutes.Auth}`;
      case CustomEntitiesEnum.Users:
        return `${this.basePath}/${GenericRoutes.Users}`;
      case CustomEntitiesEnum.Terms:
        return `${this.basePath}/${GenericRoutes.TermsOfUse}`;
      default:
        return '';
    }
  }
}
