import { Component, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';

/**
 * This component is used in forms to display/edit textarea with Tinymce
 */
@Component({
  selector: 'tc-formly-textarea-editor-type',
  template: `
    <editor
      *ngIf="initialized"
      [formControl]="formControl"
      [formlyAttributes]="field"
      apiKey="jm5l0sbkv59pk5q0kr2w4abd8oczqwotyo9nkjvg6jxoav39"
      [init]="config"
      [initialValue]="
        formControl.value || (to.initialValue ? to.initialValue : '')
      "
      [ngClass]="{ 'tc-formly-textarea-disabled': isDisabled }"
    ></editor>
  `,
})
export class TcFormlyTextareaFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  /**
   * Property to know if the component is initialized: Known tinyMce issue -input element needs to exist in DOM for timyMCE to work, so we postpone the init
   */
  initialized = false;

  /**
   * Property to know if the textarea should be disabled
   */
  isDisabled = false;

  /**
   * Default config: this can be overwrite for each form field
   */
  config = {
    branding: false,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor textcolor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table contextmenu paste code help wordcount',
    ],
    toolbar:
      'insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
    inline: false,
    language: 'fr_FR',
  };

  /**
   * @ignore
   */
  constructor() {
    super();
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    if (this.props.disabled) {
      this.isDisabled = true;
      this.formControl.disable();
    }

    //Keep the default config but replace properites specified by the developer
    if (this.props.config) {
      this.config = {
        ...this.config,
        ...this.props.config,
      };
    }

    // KRN-472: Known tinyMce issue: input element needs to exist in DOM for timyMCE to work, so we postpone the init
    setTimeout(() => {
      this.initialized = true;
    });
  }
}
