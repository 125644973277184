import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Store, select } from '@ngrx/store';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
  getTcData,
  loadTcData,
} from '@tc/data-store';
import { selectByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { distinctUntilChanged, filter } from 'rxjs/operators';

/**
 * This component is a copy of the FormlyFieldSelect (V) compoennt form formly with all it's functionalities
 * + a dataSource functionality
 * + allow a empty value option ( {value: '', label: 'N/A'} ) functionality
 */
@Component({
  selector: 'tc-formly-smart-select',
  templateUrl: './tc-formly-smart-select.component.html',
})
export class TcFormlySmartSelectComponent extends FieldType<FieldTypeConfig> implements OnInit {
  /**
   * @ignore
   * Base formly functionality from version v5.6.1 that got removed in later verions.
   * Needs to be declared in order to allow an empty value (option: {value: '', label: 'N/A'}).
   * Otherwise the input label collapses ofer the option label.
   */
  @ViewChild(MatSelect, { static: true }) formFieldControl!: MatSelect;

  /**
   * @ignore
   * Base formly functionality
   * The default options of the component passed through FieldType
   */
  defaultOptions = {
    templateOptions: {
      options: [],
      compareWith(o1: any, o2: any) {
        return o1 === o2;
      },
      /**
       * default value as the mat-select doesn't support lazy load and until it is developed
       * we must load everything. I chose 1000 because I have to specify it otherwise it will default to
       * the default page size (200). 1000 options in a list is already too much but for now it will have to do.
       * Also any number bigger than this will get reduced to 1000 as we have a limit set in the backend.
       */
      take: 1000,
    },
  };

  /**
   * @ignore
   * Base formly functionality
   */
  private selectAllValue!: { options: any; value: any[] };

  /**
   * @ignore
   * Base formly functionality
   */
  getSelectAllState(options: any[]) {
    if (this.empty || this.value.length === 0) {
      return null;
    }

    return this.value.length !== this.getSelectAllValue(options).length
      ? 'indeterminate'
      : 'checked';
  }

  /**
   * @ignore
   * Base formly functionality
   */
  toggleSelectAll(options: any[]) {
    const selectAllValue = this.getSelectAllValue(options);
    this.formControl.setValue(
      !this.value || this.value.length !== selectAllValue.length
        ? selectAllValue
        : []
    );
    this.formControl.markAsDirty();
  }

  /**
   * @ignore
   * Base formly functionality
   * Calls the change function specified by the user in case there is one.
   * @param $event
   */
  change($event: MatSelectChange) {
    this.props.change?.(this.field, $event);
  }

  /**
   * @ignore
   * Base formly functionality
   */
  _getAriaLabelledby() {
    if (this.props.attributes?.['aria-labelledby']) {
      return this.props.attributes['aria-labelledby'] as string;
    }

    return this.formField?._labelId;
  }

  /**
   * @ignore
   * Base formly functionality
   */
  _getAriaLabel() {
    return this.props.attributes?.['aria-label'] as string;
  }

  /**
   * @ignore
   * Base formly functionality
   */
  private getSelectAllValue(options: any[]) {
    if (!this.selectAllValue || options !== this.selectAllValue.options) {
      const flatOptions: any[] = [];
      options.forEach((o) =>
        o.group ? flatOptions.push(...o.group) : flatOptions.push(o)
      );

      this.selectAllValue = {
        options,
        value: flatOptions.filter((o) => !o.disabled).map((o) => o.value),
      };
    }

    return this.selectAllValue.value;
  }

  //  **** Custom login starts here ****

  /**
   * Observable of the data store
   */
  private dataStore$: Observable<NgRxTcDataState>;

  /**
   * Field used as option label
   */
  public labelFieldName: string;

  /**
   * The name of the field for which to consider the value (Optional)
   */
  public valueFieldName?: string;

  /**
   * The label to appear as selected when default value is set
   */
  public defaultValueLabel?: string;

  /**
   * The default value that the control should have
   */
  public defaultValue?: string;

  /**
   * The items from the data store
   */
  public items: any[];

  /**
   * Subscription to the data store for items
   */
  private itemsSubscription: Subscription;

  /**
   * Custom labels for the select options
   */
  public labelFn?: (item: any) => string;

  /**
   * @ignore
   */
  constructor(private readonly _cdRef: ChangeDetectorRef, private readonly store$: Store<any>) {
    super();
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
    
    // If we have a data provider we need to load the data into the store and
    // subscribe to it. Otherwise this component acts like a regular FormlySelect component.
    if (this.props.dataProvider) {
      const {
        storeKey,
        dataProvider: { fields: defaultLabelFieldName },
        labelFieldName,
        valueFieldName,
        defaultValueLabel,
        defaultValue,
        labelFn,
      } = this.props;

      this.defaultValue = defaultValue;
      this.defaultValueLabel = defaultValueLabel;
      this.labelFieldName = labelFieldName || defaultLabelFieldName;
      this.valueFieldName = valueFieldName;
      this.labelFn = labelFn;

      this.loadData();

      if (this.isDefaultValueDefined() && !this.valueFieldName) {
        this.value = this.getDefaultValue();
      }

      this.itemsSubscription = selectByKey(
        getTcData,
        this.dataStore$,
        storeKey
      ).subscribe((options) => {
        const val = this.value; // Get current value before updating items since they could already be defined
        if (this.isDefaultValueDefined()) {
          this.items = [this.getDefaultValue(), ...options];
        } else {
          this.items = options;
        }
        this._cdRef.detectChanges();
        if (!this.valueFieldName) {
          // Reset the current value to the previously selected item only if the valueFieldName is not defined -> setting object as value
          this.value = this.items.find(
            (i) => i?.[this.valueFieldName] === val?.[this.valueFieldName]
          );
        }
      });
    }
  }

  private isDefaultValueDefined(): boolean {
    return (
      this.defaultValue !== undefined &&
      this.defaultValue !== null &&
      this.defaultValueLabel !== undefined &&
      this.defaultValueLabel !== null
    );
  }

  private getDefaultValue(): any {
    return {
      [this.labelFieldName]: this.defaultValueLabel,
      [this.valueFieldName]: this.defaultValue,
    };
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.itemsSubscription?.unsubscribe();
  }

  /**
   * Dispatches the loadTcData action thus making a request
   * to the server for the options and loading the into the store.
   */
  loadData() {
    this.store$.dispatch(
      loadTcData({
        storeKey: this.props.storeKey,
        skip: 0,
        take: this.props.take,
      })
    );
  }

  /**
   * Returns what is emited by the select when the an option is selected
   * @param item i.e. selected option
   * @returns Default value is the whole option. But if the multiselect
   * the distinct property in the dataSource that it return just the value of the distinct option
   */
  itemValue(item) {
    if (this.props.dataProvider.distinct) {
      return item[this.valueFieldName || this.labelFieldName];
    }

    return item[this.valueFieldName] ?? item;
  }
}
