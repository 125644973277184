import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { TcGenericEntity } from '../abstract/tc-generic-entity.interface';
import { TcGenericListAction } from '../abstract/tc-generic-list-action.interface';
import { TcGenericListColumn } from '../abstract/tc-generic-list-column.interface';
import { FilterTypesEnum } from '@tc/abstract';
import { TcListRowActionButtonsPosition } from '../enums/tc-list-action-buttons-position.enum';
import { TcListDefaultAction } from '../enums/tc-list-default-action.enum';
import { TcListFilterType } from '../enums/tc-list-filter-type.enum';
import { TcListSortType } from '../enums/tc-list-sort-type.enum';
import { TcFilter } from '../interfaces/tc-filter';
import { TcFilterValue } from '../interfaces/tc-filter-value';
import { TcFilterTypes } from '@tc/abstract';
import { getValueByPath } from '@tc/utils';

@Directive()
export class TcList implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  rows: any[] = [];

  @Input()
  rows$: Observable<any[]>;

  @Input()
  columns: TcGenericListColumn[] = [];

  @Input()
  listName: string;

  @Input()
  showTotalCount = false;

  @Input()
  hasActionsLabel = true;

  @Input()
  showTotalInActionsHeader = false;

  @Input()
  filters: TcFilter[] = [];

  @Input()
  allowDragAndDrop = false;

  @Input()
  dragAndDropCssClass = '';

  @Input()
  dragStartDelay = 0;

  @Input()
  hasFixedHeader = false;

  @Input()
  isPaged = true;

  @Input()
  pageSize = 10;

  @Input()
  pageSizeOptions = [10, 20, 50, 100];

  @Input()
  pageShowFirstLastButtons = false;

  @Input()
  rowActions: TcGenericListAction[] = [];

  @Input()
  bulkActions: TcGenericListAction[] = [];

  @Input()
  infiniteScrollDistance = 2;

  @Input()
  infiniteScrollUpDistance = 1.5;

  @Input()
  infiniteScrollThrottle = 150;

  @Input()
  infiniteScrollWindow = false;

  @Input()
  infiniteScrollDisabled = false;

  @Input()
  infiniteScrollHorizontal = false;

  @Input()
  sortType = TcListSortType.Client;

  @Input()
  sortActive: string;

  @Input()
  sortDirection: string;

  @Input()
  sortStart: 'asc' | 'desc' = 'desc';

  @Input()
  filterType = TcListFilterType.Client;

  @Input()
  rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;

  @Input()
  hasAddButton = false;

  @Input()
  addItemWhenKeyPresed = false;

  @Input()
  keyForAddingItem = 'c';

  @Input()
  hasTotalLine = false;

  @Input()
  totalLineColumns: string[] = [];

  @Input()
  totalDisplayColumn: string;

  @Input()
  totalCustomFunctions: { [property: string]: (item: any) => any };

  @Input()
  isFiltrable = true;

  public filterLabel = '';

  isPaginationVisible = true;

  totalCount = 0;
  totalItems = 0;

  actionsHeaderLabel = '';

  canLoadMoreResults = true;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public displayedColumns = [];
  public dataSource = new MatTableDataSource<any>();

  public selectedFilterValues: TcFilterValue = {};

  selection = new SelectionModel<any>(true, []);

  protected rowsSubscription: Subscription;

  lastScrollTop = 0;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  applyServerSideFilter() {}

  applyClientSideFilter() {
    // Set random value to force filterPredicate method
    this.dataSource.filter = `${new Date().getTime()}`;
  }

  getPropertyValue(elem: any, propertyName: string): string {
    if (propertyName.includes('.')) {
      const values = propertyName.split('.');
      return elem[values[0]] ? elem[values[0]][values[1]] : null;
    }
    return elem[propertyName];
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isCheckboxFocused =
      (event.target as HTMLInputElement).type === 'checkbox';

    const shouldProcess =
      this.addItemWhenKeyPresed &&
      event.key.toUpperCase() === this.keyForAddingItem.toUpperCase() &&
      (isCheckboxFocused || (event.target as any).localName === 'body');

    if (!shouldProcess) {
      return;
    }

    this.addItem();

    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  constructor(elem: ElementRef) {
    this.listName = elem.nativeElement.id;
  }

  onScrollList(event) {
    const scrollTop = event.srcElement.scrollTop;
    const scrollHeight = event.srcElement.scrollHeight;
    const clientHeight = event.srcElement.clientHeight;

    if (scrollTop === 0) {
      this.lastScrollTop = 0;
    }

    if (scrollTop > this.lastScrollTop) {
      console.log('down');
      const triggerPosition = 0.2 * clientHeight + clientHeight + scrollTop;
      if (
        triggerPosition > scrollHeight &&
        this.totalCount !== this.totalItems &&
        this.canLoadMoreResults === true
      ) {
        this.onScrollDown();
        this.canLoadMoreResults = false;
      }
    } else {
      console.log('up');
    }

    this.lastScrollTop = scrollTop;

    // console.log('scrollTop: ', scrollTop);
    // console.log('scrollHeight: ', scrollHeight);
    // console.log('clientHeight: ', clientHeight);
  }

  getTotalLine = (values: any[]): any => {
    const total = {};

    if (values && values.length > 0) {
      (total as any).totalLine = true;

      this.totalLineColumns.forEach(column => {
        let totalColumn = null;
        if (this.totalCustomFunctions && this.totalCustomFunctions[column]) {
          const valuesMap = values.map(value => {
            return this.totalCustomFunctions[column](value);
          });
          totalColumn = valuesMap.reduce(
            (acc, value) => Number(acc) + Number(value),
            0
          );
        } else {
          totalColumn = values
            .map(t => t[column])
            .reduce((acc, value) => Number(acc) + Number(value), 0);
        }

        if (totalColumn) {
          total[column] = totalColumn.toFixed(2);
        }
      });

      if (this.totalDisplayColumn) {
        if (this.totalDisplayColumn.includes('.')) {
          const parts = this.totalDisplayColumn.split('.');
          total[parts[0]] = {};
          total[parts[0]][parts[1]] = 'TOTAL';
        } else {
          total[this.totalDisplayColumn] = 'TOTAL';
        }
      }

      // let totalLabel = 0;
      // for (const line of values) {
      //   Object.keys(line).forEach(key => {
      //     const val = line[key];
      //     const numVal = parseFloat(val);
      //     if (!isNaN(val)) {
      //       if (total[key] === null || total[key] === undefined || !total[key]) {
      //         total[key] = 0;
      //       }
      //       total[key] += numVal;
      //     } else {
      //       if (totalLabel === 0) {
      //         total[key] = 'TOTAL';
      //         totalLabel = 1;
      //       } else {
      //         if (total[key] !== 'TOTAL') {
      //           total[key] = '';
      //         }
      //       }
      //     }
      //   });
      // }

      // // Round the numbers
      // Object.keys(total).forEach(key => {
      //   if (total[key] !== '' &&
      //     total[key] !== 'TOTAL') {
      //     total[key] = Number.parseFloat(total[key]).toFixed(2);
      //   }
      // });
    }

    return total;
  };

  calculateTotalLine = () => {
    if (this.hasTotalLine && this.totalLineColumns.length > 0) {
      const totalLine = this.getTotalLine(this.dataSource.data);
      if (totalLine && Object.keys(totalLine).length === 0) {
        this.dataSource.data = this.dataSource.data.filter(d => !d.totalLine);

        this.dataSource.data = this.dataSource.data.concat([totalLine]);
      }
    }
  };

  ngOnInit() {
    this.setFilterPredicate();

    if (this.rows$) {
      this.rowsSubscription = this.rows$.subscribe(items => {
        this.rows = items;
        this.totalCount = this.rows.length;
        this.dataSource.data = this.rows;
        if (this.rows.length > 0) {
          this.canLoadMoreResults = true;
        }
        this.calculateTotalLine();
      });
    } else {
      this.totalCount = this.rows.length;
      this.dataSource.data = this.rows;
      this.calculateTotalLine();
    }
    if (this.listName) {
      if (this.isFiltrable) {
        this.filterLabel = this.listName + '.filter.label';
      }
      if (this.hasActionsLabel) {
        this.actionsHeaderLabel = this.listName + '.headers.actions';
      }
      this.columns.map(c => {
        c.headerLabel = this.listName + '.headers.' + c.propertyName;
      });
      this.bulkActions.map(ba => {
        ba.actionLabel = this.listName + '.bulk-actions.' + ba.actionName;
      });
      this.rowActions.map(ra => {
        ra.actionLabel = this.listName + '.row-actions.' + ra.actionName;
      });
    }
    if (this.rowActions.filter(c => c.visible).length > 0) {
      if (
        this.rowActionButtonsPosition ===
        TcListRowActionButtonsPosition.BeforeData
      ) {
        if (this.bulkActions.filter(c => c.visible).length > 0) {
          this.displayedColumns.push('select');
        }
        this.displayedColumns.push('actions');
        this.columns
          .filter(c => c.visible)
          .forEach(c => this.displayedColumns.push(c.propertyName));
      } else {
        if (this.bulkActions.filter(c => c.visible).length > 0) {
          this.displayedColumns.push('select');
        }
        this.columns
          .filter(c => c.visible)
          .forEach(c => this.displayedColumns.push(c.propertyName));
        this.displayedColumns.push('actions');
      }
    } else {
      if (this.bulkActions.filter(c => c.visible).length > 0) {
        this.displayedColumns.push('select');
      }
      this.columns
        .filter(c => c.visible)
        .forEach(c => this.displayedColumns.push(c.propertyName));
    }
    if (this.sortType === TcListSortType.Disabled) {
      this.sortActive = null;
      this.sortDirection = null;
    }
    if (this.showTotalInActionsHeader === true) {
      this.showTotalCount = false;
    }
  }

  ngAfterViewInit(): void {
    if (!this.allowDragAndDrop && this.sortType === 'client') {
      this.dataSource.sort = this.sort;
    }

    if (this.isPaged) {
      this.dataSource.paginator = this.paginator;
    }
  }

  getDefaultFilter(): TcFilter {
    return {
      key: TcFilterTypes.anyFieldContains,
      filterType: FilterTypesEnum.Equal,
      type: TcFilterTypes.anyFieldContains,
      label: `${this.listName}.filter.label`,
      options: {
        minLength: 1,
        debounceTime: 500,
      },
    };
  }

  ngOnDestroy() {
    if (this.rows$ && this.rowsSubscription) {
      this.rowsSubscription.unsubscribe();
    }
  }

  getVisibleColumns(): TcGenericListColumn[] {
    return this.columns.filter(c => c.visible);
  }

  getFiltrableColumns(): TcGenericListColumn[] {
    return this.columns.filter(c => c.filtrable);
  }

  getRowActions(): TcGenericListAction[] {
    if (this.rowActions) {
      return this.rowActions.filter(a => a.visible);
    } else {
      return [];
    }
  }

  getBulkActions(): TcGenericListAction[] {
    if (this.bulkActions) {
      return this.bulkActions.filter(a => a.visible);
    } else {
      return [];
    }
  }

  onTriggerRowClick(row: TcGenericEntity) {
    if (!(row as any).totalLine) {
      this.onRowClick(row);
    }
  }

  onRowActionClick(row: TcGenericEntity, actionName: string) {
    if (actionName === TcListDefaultAction.Delete) {
      this.onDeleteAction(row);
    } else if (actionName === TcListDefaultAction.Duplicate) {
      this.onDuplicateRow(row);
    } else {
      this.onRowAction(row, actionName);
    }
  }

  onDeleteAction(row: TcGenericEntity) {}

  onRowAction(row: TcGenericEntity, actionName: string) {}

  onBulkAction(actionName: string) {}

  onRowClick(row: TcGenericEntity) {}

  setRowClass(row: TcGenericEntity) {}

  afterDragAndDrop(lastIndex: number, newIndex: number) {}

  onScrollUp() {}

  onScrollDown() {}

  applyServerSideSort(sort: Sort) {}

  applyClientSideSort(sort: Sort) {}

  addItem() {}

  onDuplicateRow(row: TcGenericEntity) {}

  private setFilterPredicate() {
    this.dataSource.filterPredicate = (tableRow: Element, _: any) => {
      const filterKeys = Object.keys(this.selectedFilterValues).filter(key => {
        if (
          [
            TcFilterTypes.anyFieldContains,
            TcFilterTypes.anyFieldStartsWith,
          ].includes(key as TcFilterTypes)
        ) {
          return true;
        }

        if (Array.isArray(this.selectedFilterValues[key].value)) {
          return !!this.selectedFilterValues[key].value.length;
        }

        return !!this.selectedFilterValues[key].value;
      });

      return filterKeys.every(key => {
        const value = this.selectedFilterValues[key].value;

        if (TcFilterTypes.anyFieldContains === key) {
          const rowValues = [
            ...Object.values(tableRow).map(
              v => (v as string)?.trim().toLowerCase() || ''
            ),
          ];
          return rowValues.some(v =>
            v.includes(this.selectedFilterValues[key] as any)
          );
        }

        if (TcFilterTypes.anyFieldStartsWith === key) {
          const rowValues = [
            ...Object.values(tableRow).map(
              v => (v as string)?.trim().toLowerCase() || ''
            ),
          ];
          return rowValues.some(v =>
            v.startsWith(this.selectedFilterValues[key] as any)
          );
        }

        const isDateRange = value?.start && value?.end;
        if (isDateRange) {
          if (!getValueByPath(tableRow, key, '')) {
            return false;
          }

          const tableValue = moment(tableRow[key], value.format);
          return value.start <= tableValue && value.end >= tableValue;
        }

        if (this.selectedFilterValues[key].filterType === FilterTypesEnum.In) {
          return (value as any[]).includes(getValueByPath(tableRow, key, ''));
        }

        if (
          this.selectedFilterValues[key].filterType === FilterTypesEnum.Equal
        ) {
          return value === getValueByPath(tableRow, key, '');
        }

        if (
          this.selectedFilterValues[key].filterType === FilterTypesEnum.IsNull
        ) {
          return !getValueByPath(tableRow, key, null);
        }

        if (
          this.selectedFilterValues[key].filterType ===
          FilterTypesEnum.IsNotNullOrEmptyString
        ) {
          return !getValueByPath(tableRow, key, null);
        }

        if (
          this.selectedFilterValues[key].filterType ===
          FilterTypesEnum.IsNotEmpty
        ) {
          return !getValueByPath(tableRow, key, null);
        }

        if (
          this.selectedFilterValues[key].filterType === FilterTypesEnum.NotEqual
        ) {
          return getValueByPath(tableRow, key, null) !== value;
        }

        return true;
      });
    };
  }
}
