import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  EntityCollectionService,
  EntityCollectionServiceFactory,
} from '@ngrx/data';
import { FilterTypesEnum } from '@tc/abstract';
import { TcFilterTypes } from '@tc/abstract';
import { TcSimpleFilterComponent } from '../../dumb/tc-simple-filter/tc-simple-filter.component';

@Component({
  selector: 'tc-generic-filter',
  templateUrl: './tc-generic-filter.component.html',
  styleUrls: ['./tc-generic-filter.component.css'],
})
export class TcGenericFilterComponent<T> implements OnInit {
  @Input()
  entityName: string;

  @Input()
  filterLabel: string;

  @Input()
  minLength = 1;

  @Input()
  debounceTime = 500;

  @Input()
  filterExpression: 'anyFieldContains' | 'anyFieldStartsWith' =
    'anyFieldContains';

  value: string;

  service: EntityCollectionService<T>;

  tcSimpleFilter: TcSimpleFilterComponent;
  @ViewChild('tcSimpleFilter', { static: true }) set setTcSimpleFilterComponent(
    values: TcSimpleFilterComponent
  ) {
    this.tcSimpleFilter = values;
  }

  constructor(
    private entityCollectionServiceFactory: EntityCollectionServiceFactory
  ) {}

  ngOnInit() {
    this.service = this.entityCollectionServiceFactory.create(this.entityName);
    this.tcSimpleFilter.item = {
      key: this.filterExpression,
      type: TcFilterTypes.anyFieldContains,
      filterType: FilterTypesEnum.Equal,
      label: this.filterLabel,
      options: {
        minLength: this.minLength,
        debounceTime: this.debounceTime,
      },
    };

    this.tcSimpleFilter.value = this.value;
  }

  onSearch = (filterValue: string) => {
    this.service.clearCache();
    this.service.getWithQuery({
      filter: JSON.stringify({
        [this.filterExpression]: filterValue,
      }),
    });
  };
}
