export enum TcFormlyComponent {
  /**
   * Standard formly field types
   * Standard native-select, slider and datepicker, autocomplete don't work out of the box and need to be implemented as in:
   * https://stackblitz.com/angular/ndolxarqyab?file=src%2Fapp%2Fautocomplete-type.component.ts
   * You can find all examples here: https://formly.dev/ui/material
   */
  FormlyInput = 'input',
  FormlyTextarea = 'textarea',
  FormlyCheckbox = 'checkbox',
  FormlyRadio = 'radio',
  FormlySelect = 'select',
  FormlyToggle = 'toggle',
  // FormlyDatePicker = 'datepicker', Works but not needed because of TcDatePicker
  // FormlyNativeSelect = 'native-select',
  // FormlySlider = 'slider',
  // FormlyAutocomplete = 'autocomplete',

  /**
   * Custom Field types adapted to tcp needs
   */
  TcMultiSelect = 'tc-multi-select',
  TcAutoComplete = 'tc-autocomplete',
  TcFileUploader = 'tc-file-uploader',
  TcDatePicker = 'tc-date-picker',
  TcDateRangePicker = 'tc-date-range-picker',
  /**
   * Customised to only the basic needs, i.e. work with a data provider.
   */
  TcSmartSelect = 'tc-formly-smart-select',

  FormlySmartSelect = 'formly-field-mat-select',

  /**
   * The following compoennts are not being used at the moment but
   * we might need to adapt these in the future.
   * They also have some bugs and need to be tested
   */

  /**
   * The model doesn't get updated with and you can't write in it
   */
  // TcInput = 'tc-input',

  /**
   * We need some work on html and css
   */
  TcTextarea = 'tc-textarea',

  /**
   * TODO: I found this component in a very poor state so I chose to leave it as it is and create
   *       a new one from scrach based on the FormlySelect component as time was of the essence.
   *       It might be the case that we should detele it.
   * We need to be adapted to use dataprovider. Also label doesn't stay up after select because the input value is not emitted properly I believe
   */
  // TcSelect = 'tc-select',

  /**
   * Needs to be tested
   */
  // TcButton = 'tc-button',
}
