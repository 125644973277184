export enum NiveauParteneriatAssociation {
    Aucun = 0,
    TicadiSeul = 1,
    ProxidonSeul = 2,
    ProxidonAndTicadi = 3,
    CompteProxidonDeTest = 4,
    CompteProxidonBAAggisantCommeUneAssociation = 5,
    PacobaSeul = 6,
    PacobaAndTicadi = 7,
    PacobaAndTicadiAndProxidon = 8,
    PacobaAndProxidon = 9,
}

export enum NiveauParteneriatAssociationToString {
    aucun,
    ticadiSeul,
    proxidonSeul,
    proxidonAndTicadi,
    compteProxidonDeTest,
    compteProxidonBAAggisantCommeUneAssociation,
    pacobaSeul,
    pacobaAndTicadi,
    pacobaAndTicadiAndProxidon,
    pacobaAndProxidon,
}