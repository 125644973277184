import {
  HttpErrorsActionTypes,
  AddHttpError,
  Done,
} from '../actions/http-error.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class HttpErrorEffects {
   onLoadError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HttpErrorsActionTypes.AddHttpError),
      map((error: AddHttpError) => {
        if (error instanceof HttpErrorResponse) {
          const appErrorHandler = this.injector.get(ErrorHandler);
          appErrorHandler.handleError(error);
        }
  
        return Done();
      }));  
   }
  );

  constructor(private actions$: Actions, private injector: Injector) {}
}
