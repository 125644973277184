import { Injectable } from '@angular/core';
import { TcGridAutocompleteEditorComponent } from '../components/tc-grid-autocomplete-editor/tc-grid-autocomplete-editor.component';
import { TcGridButtonsRendererComponent } from '../components/tc-grid-buttons-renderer/tc-grid-buttons-renderer.component';
import { TcGridCheckboxEditorComponent } from '../components/tc-grid-checkbox-editor/tc-grid-checkbox-editor.component';
import { TcGridCheckboxRendererComponent } from '../components/tc-grid-checkbox-renderer/tc-grid-checkbox-renderer.component';
import { TcGridConcatArrayRendererComponent } from '../components/tc-grid-concat-array-renderer/tc-grid-concat-array-renderer.component';
import { TcGridDatePickerEditorComponent } from '../components/tc-grid-datepicker-editor/tc-grid-datepicker-editor.component';
import { TcGridEventButtonsRendererComponent } from '../components/tc-grid-event-buttons-renderer/tc-grid-event-buttons-renderer.component';
import { TcGridFaButtonsRendererComponent } from '../components/tc-grid-fa-buttons-renderer/tc-grid-fa-buttons-renderer.component';
import { TcGridHeaderSelectionComponent } from '../components/tc-grid-header-selection/tc-grid-header-selection.component';
import { TcGridHtmlRendererComponent } from '../components/tc-grid-html-renderer/tc-grid-html-renderer.component';
import { TcGridLinkRendererComponent } from '../components/tc-grid-link-renderer/tc-grid-link-renderer.component';
import { TcGridEmailRendererComponent } from '../components/tc-grid-mail-renderer/tc-grid-email-renderer.component';
import { TcGridMassUpdateHeaderComponent } from '../components/tc-grid-mass-update-header/tc-grid-mass-update-header.component';
import { TcGridMatInputEditorComponent } from '../components/tc-grid-mat-input-editor/tc-grid-mat-input-editor.component';
import { TcGridMultiSelectEditorComponent } from '../components/tc-grid-multi-select-editor/tc-grid-multi-select-editor.component';
import { TcGridNoRowsOverlayComponent } from '../components/tc-grid-no-rows-overlay/tc-grid-no-rows-overlay.component';
import { TcGridPhoneRendererComponent } from '../components/tc-grid-phone-renderer/tc-grid-phone-renderer.component';
import { TcGridSmartButtonsRendererComponent } from '../components/tc-grid-smart-buttons-renderer/tc-grid-smart-buttons-renderer.component';
import { TcGridTemplateRendererComponent } from '../components/tc-grid-template-renderer/tc-grid-template-renderer.component';
import { TcGridCellComponent } from '../enums/tc-grid.enum';
import { TcGridComboEditorComponent } from '../components/tc-grid-combo-editor/tc-grid-combo-editor.component';
import { TcGridComboRendererComponent } from '../components/tc-grid-combo-renderer/tc-grid-combo-renderer.component';

@Injectable({ providedIn: 'root' })
export class TcGridColumnConfigProviderService {
  public config: any = {
    [TcGridCellComponent.HeaderWithSelection]: TcGridHeaderSelectionComponent,
    [TcGridCellComponent.HtmlRenderer]: TcGridHtmlRendererComponent,
    [TcGridCellComponent.ButtonsRenderer]: TcGridButtonsRendererComponent,
    [TcGridCellComponent.FaButtonsRenderer]: TcGridFaButtonsRendererComponent,
    [TcGridCellComponent.EventButtonRenderer]:
      TcGridEventButtonsRendererComponent,
    [TcGridCellComponent.SmartButtonRenderer]:
      TcGridSmartButtonsRendererComponent,
    [TcGridCellComponent.MatInputEditor]: TcGridMatInputEditorComponent,
    [TcGridCellComponent.CheckboxEditor]: TcGridCheckboxEditorComponent,
    [TcGridCellComponent.DatePickerEditor]: TcGridDatePickerEditorComponent,
    [TcGridCellComponent.AutoCompleteEditor]: TcGridAutocompleteEditorComponent,
    [TcGridCellComponent.MultiSelectEditor]: TcGridMultiSelectEditorComponent,
    [TcGridCellComponent.ComboEditor]: TcGridComboEditorComponent,
    [TcGridCellComponent.ComboRenderer]: TcGridComboRendererComponent,
    [TcGridCellComponent.NoRowsOverlay]: TcGridNoRowsOverlayComponent,
    [TcGridCellComponent.MassUpdateHeader]: TcGridMassUpdateHeaderComponent,
    [TcGridCellComponent.CheckboxRenderer]: TcGridCheckboxRendererComponent,
    [TcGridCellComponent.TemplateRenderer]: TcGridTemplateRendererComponent,
    [TcGridCellComponent.ConcatArrayRenderer]:
      TcGridConcatArrayRendererComponent,
    [TcGridCellComponent.LinkRenderer]: TcGridLinkRendererComponent,
    [TcGridCellComponent.EmailRenderer]: TcGridEmailRendererComponent,
    [TcGridCellComponent.PhoneRenderer]: TcGridPhoneRendererComponent,
  };

  public getConfig(options) {
    return {
      ...this.config,
      ...(options?.frameworkComponents ?? {}),
    };
  }
}
