import { ElementRef, HostListener, OnDestroy, OnInit, Directive } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  EntityCollectionService,
  EntityCollectionServiceFactory,
} from '@ngrx/data';
import { Subscription } from 'rxjs';
import { TcGenericEntity } from '../../abstract/tc-generic-entity.interface';
import { TcGenericForm } from '../../abstract/tc-generic-form.interface';
import { TcTranslateService } from '../../services/tc-translate.service';
import { TcFormComponent } from '../tc-form/tc-form.component';

@Directive()
export class TcGenericFormComponent<T extends TcGenericEntity>
  extends TcFormComponent<T>
  implements OnInit, OnDestroy, TcGenericForm<T>
{
  id: string | number;

  service: EntityCollectionService<T>;
  serviceSubscription: Subscription;

  constructor(
    public entityName: string,
    public entityCollectionServiceFactory: EntityCollectionServiceFactory,
    public translate: TcTranslateService,
    public elem: ElementRef,
    public dateAdapter: DateAdapter<any>
  ) {
    super(translate, elem);
  }

  @HostListener('document:keydown.control.enter', ['$event'])
  handleSaveEvent(event: KeyboardEvent) {
    if (this.tcForm) {
      this.form.markAsTouched();
      (this.tcForm as any).submitted = true;
      this.submit(this.model);
    }
  }

  ngOnInit(): void {
    this.service = this.entityCollectionServiceFactory.create(this.entityName);

    if (this.id) {
      this.serviceSubscription = this.service
        .getByKey(this.id)
        .subscribe((item) => {
          this.model = item;
        });
    }

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }

  afterSave(model?: T) {}

  public submit(model: T) {
    if (this.form.valid) {
      const parsedModel: T = {
        ...model,
        ...this.parseFormFields(model, this.fields),
      };
      if (model.id && model.id > 0) {
        this.service.update(parsedModel).subscribe((next) => {
          this.afterSave(parsedModel);
        });
      } else {
        this.service.add(parsedModel).subscribe((next) => {
          this.afterSave(parsedModel);
        });
      }
    }
  }

  private parseFormFields(model: T, fields: FormlyFieldConfig[]) {
    let parsedModel = {};
    fields
      .filter((f) => f.type === 'datepicker')
      .forEach((f) => {
        if (
          (typeof f.key === 'string' || typeof f.key === 'number') &&
          model[f.key] !== null &&
          model[f.key] !== undefined
        ) {
          parsedModel[f.key] = this.dateAdapter
            .deserialize(model[f.key])
            .format('YYYY-MM-DD');
        }
      });
    fields
      .filter((f) => f.type === 'autocomplete-list')
      .forEach((f) => {
        if (
          (typeof f.key === 'string' || typeof f.key === 'number') &&
          model[f.key] === ''
        ) {
          parsedModel[f.key] = null;
        }
      });
    fields
      .filter((f) => f.type === 'formly-group')
      .forEach((group) => {
        parsedModel = {
          ...parsedModel,
          ...this.parseFormFields(model, group.fieldGroup),
        };
      });
    return parsedModel as T;
  }
}
