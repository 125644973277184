import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcComponentLookup,
  TcFormlyComponent,
  TcFormlyWrapper,
} from '@tc/core';
import { Component } from '@angular/core';
import {
  submitAndCloseDialogButtonClicked,
  TcSmartDetailPopupComponent,
} from '@tc/advanced-components';
import {
  FilterTypesEnum,
  MaterialColor,
  TcConfigTypes,
  TcDataProviderType,
  ListOrder,
} from '@tc/abstract';
import { TcSmartButton } from '@tc/buttons';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormInvalidity,
} from '@tc/smart-form';
import { PermissionAction } from '../../../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../../../typings/permission-subject.enum';

@TcComponentLookup('AssociationsDetailsComponent')
@Component({
  selector: 'app-associations-details',
  templateUrl: './associations-details.component.html',
  styleUrls: ['./associations-details.component.scss'],
})
export class AssociationsDetailsComponent extends TcSmartDetailPopupComponent {
  formStoreKey = 'associations-grid';

  async setFormConfig() {
    this.dialogConfig.dialogStoreKey = this.formStoreKey;

    this.formConfig = {
      permissionAction: PermissionAction.UpdateAssociations,
      permissionSubject: PermissionSubject.All,
      configType: TcConfigTypes.TcForm,
      storeKey: this.formStoreKey, //Maybe  + -detail
      autoSubmit: false,
      fieldConfigs: [],
      titleConfig: {
        storeKey: this.formStoreKey,
        configType: TcConfigTypes.TcDetailTitle,
        titlePrefix: 'associations-grid.detail.ficheAssociation',
        titleProperty: '_id',
        hasGenericSaveButton: true,
        genericSaveButtonAction: submitAndCloseDialogButtonClicked,
      },
      headerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
        headerText: 'associations-grid.detail.associationDetail',
      },
      footerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
        headerText: '',
        buttonsList: this.getHeaderButtons(),
      },
    };

    this.dialogConfig.headerConfig.enableActionsSlot = false;

    this.dialogConfig.footerConfig.enableActionsSlot = true;

    this.initFields();
  }

  initFields() {
    this.formConfig.fieldConfigs = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'supprime',
                defaultValue: false,
                hide: true,
              }),
              formlyControl({
                key: 'nom',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: true,
                  type: 'text',
                },
                lgColSpan: 6,
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'typologies',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  required: true,
                  multiple: true,
                  compareWith: (o1, o2) => o1?.typologie === o2?.typologie,
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.BreezeJs,
                    dataSet: 'refTypologie',
                    fields: 'typologie',
                    sortOrder: { key: 'typologie', order: ListOrder.Asc },
                  },
                },
                lgColSpan: 6,
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'compteClef',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  required: false,
                  compareWith: (o1, o2) => o1?._id === o2?._id,
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.BreezeJs,
                    dataSet: 'refComptesClef',
                    fields: 'compteClef,compteActif',
                    sortOrder: {
                      key: 'compteClef',
                      order: ListOrder.Asc,
                    },
                    filter: {
                      filters: [
                        {
                          key: 'compteActif',
                          value: true,
                          filterType: FilterTypesEnum.Equal,
                        },
                      ],
                    },
                  },
                  labelFieldName: 'compteClef',
                },
                lgColSpan: 6,
                xxlColSpan: 3,
              }),
            ],
          }),
          formlyColumn({
            fields: [
              formlyRow({
                fields: [
                  formlyControl({
                    key: 'commentaire',
                    type: TcFormlyComponent.FormlyTextarea,
                    templateOptions: {
                      required: false,
                      type: 'text',
                      rows: 5,
                    },
                    colSpan: 12,
                  }),
                ],
              }),
              formlyRow({
                fields: [
                  formlyControl({
                    key: 'pieceJointe',
                    type: TcFormlyComponent.TcFileUploader,
                    templateOptions: {
                      required: false,
                      uploaderConfig: {
                        theme: 'inline',
                        multiple: true,
                        selectable: true,
                        fileDropDisabled: false,
                        maxSize: '1048576',
                        replaceTexts: {
                          selectFileBtn: 'Drop/Select Files',
                        },
                      },
                    },
                    colSpan: 12,
                  }),
                ],
              }),
            ],
            wrappers: [TcFormlyWrapper.ExpansionPanel],

            templateOptions: {
              label: 'autres-details',
            },
            colSpan: 12,
          }),
        ],

        colSpan: 12,
      }),
    ];
  }

  getHeaderButtons(): TcSmartButton[] {
    const headerButtons = [];

    headerButtons.push(this.getSaveButton());

    return headerButtons;
  }

  getSaveButton(): TcSmartButton {
    return {
      label: 'enregister',
      color: MaterialColor.Primary,
      matIcon: 'save',
      action: submitAndCloseDialogButtonClicked,
      actionPayload: { storeKey: this.formStoreKey },
      disableStoreKey: this.formStoreKey,
      disableSelector: getTcSmartFormInvalidity,
      smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      permissionAction: PermissionAction.UpdateAssociations,
      permissionSubject: PermissionSubject.All,
    };
  }
}
