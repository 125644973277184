import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { ConfigKeys } from '../../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../../shared/services/config.service';
import { AmsRouteProvider } from '../../../services/ams-route.provider';
import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';
import { downloadFile } from '../../../shared/utils/export.utils';
import { Association, NiveauParteneriatAssociation, NiveauParteneriatAssociationToString } from '../types';
import { AssociationCategorie } from '../types/association-categorie';
import { AssociationsResponse } from '../types/http/associations-response';

@Injectable({
  providedIn: 'root',
})
export class AssociationsService {
  private basePath = 'http://localhost:3333/api';

  constructor(
    public httpClient: HttpClient,
    private readonly store$: Store<any>,
    private readonly config: ConfigService,
    private readonly routerService: AmsRouteProvider
  ) {
    this.basePath = config.get(ConfigKeys.API_BASE_PATH);
  }

  public async getAssociationsCategoriesData(
    category: string | null = null,
    bankId: number | null = null,
    active: boolean | null = null,
    currentPartnershipLevel: NiveauParteneriatAssociation | null = null,
    newPartnershipLevel: NiveauParteneriatAssociation | null = null,
  ): Promise<AmsStatsInterface[]> {
    const categories = Object.values(AssociationCategorie);
    const params = this.createParams(
      category,
      bankId,
      active,
      currentPartnershipLevel,
      newPartnershipLevel,
    );
    let associationCategoriesStats: AmsStatsInterface[] = [];

    for (const key of categories) {
      // Remove blank from statss
      if (key === '') continue;

      let noOfAssociations = 0;

      if (category && category !== key) {
        noOfAssociations = 0;
      } else {
        params.categorie = key;

        const response = await this.getNoOfAssociationsPerCategory(params);
        noOfAssociations = response?.count ? response?.count : 0;
      }

      const label = `association-stats-${key}-label`;
      const stat: AmsStatsInterface = {
        label,
        value: noOfAssociations,
      };

      associationCategoriesStats = [...associationCategoriesStats, stat];
    }

    return of(associationCategoriesStats).toPromise();
  }

  public async getNoOfAssociationsPerCategory(params): Promise<any> {
    const url = `${this.basePath}/${
      this.routerService.getRoutes().noOfAssociationsPerCategory
    }`;

    const response = await this.httpClient.get(url, { params }).toPromise();

    return response;
  }

  public async getNoAssociationsEquiped(params): Promise<any> {
    const url = `${this.basePath}/${
      this.routerService.getRoutes().noOfAssociationsEquipped
    }`;

    const response = await this.httpClient.get(url, { params }).toPromise();

    return response;
  }

  public async getAssociationsSoftwareData(
    category: string | null = null,
    bankId: number | null = null,
    active: boolean | null = null,
    currentPartnershipLevel: NiveauParteneriatAssociation | null = null,
    newPartnershipLevel: NiveauParteneriatAssociation | null = null,
  ): Promise<AmsStatsInterface[]> {
    const partnershipLevels = [NiveauParteneriatAssociation.ProxidonSeul, NiveauParteneriatAssociation.TicadiSeul, NiveauParteneriatAssociation.Aucun];
    const params = this.createParams(
      category,
      bankId,
      active,
      currentPartnershipLevel,
      newPartnershipLevel,
    );

    let associationSoftwareData: AmsStatsInterface[] = [];

    for (const partnershipLevel of partnershipLevels) {
        let noOfAssociations = 0;
        params['niveauParteneriat'] = partnershipLevel;
        
        const response = await this.getNoAssociationsEquiped(params);
        noOfAssociations = response?.count ? response?.count : 0;
      
      const label = `association-stats-${NiveauParteneriatAssociationToString[partnershipLevel]}-label`;
      const stat: AmsStatsInterface = {
        label,
        value: noOfAssociations,
      };

      associationSoftwareData = [...associationSoftwareData, stat];
    }

    return of(associationSoftwareData).toPromise();
  }

  public async exportAssociationsListCsv(
    category: string | null = null,
    bankId: number | null = null,
    active: boolean | null = null,
    currentPartnershipLevel: NiveauParteneriatAssociation | null = null,
    newPartnershipLevel: NiveauParteneriatAssociation | null = null,
  ) {
    const params = this.createParams(
      category,
      bankId,
      active,
      currentPartnershipLevel,
      newPartnershipLevel,
    );

    const url = `${this.basePath}/associations/export-associations`;

    const response = await this.httpClient
      .get(url, { params, responseType: 'text' })
      .toPromise();

    const fileName = `Liste des associations.csv`;

    downloadFile(fileName, response);
  }

  public exportAssociationsListPdf() {
    // TODO: Logic to export associations list as pdf
  }

  public async updateAssociationNiveauParteneriatActuel(associationId: string, niveauParteneriatActuel: number) {
    const url = `${this.basePath}/associations/${associationId}/update-niveau-partneteriat-actuel`;

    const response = await this.httpClient
      .post(url, { niveauParteneriatActuel })
      .toPromise();

    return response;
  }

  private createParams(
    category: string | null = null,
    bankId: number | null = null,
    active: boolean | null = null,
    currentPartnershipLevel: NiveauParteneriatAssociation | null = null,
    newPartnershipLevel: NiveauParteneriatAssociation | null = null,
  ) {
    return {
      ...(bankId && { banqueId: bankId }),
      ...(category && { categorie: category }),
      ...(active === null ? {} : { active }),
      ...(currentPartnershipLevel && { niveauParteneriatActuel: currentPartnershipLevel }),
      ...(newPartnershipLevel && { niveauParteneriatNouveau: newPartnershipLevel }),
    };
  }

  public async getAssociationById(associationId: number): Promise<Association> {
    const url = `${this.basePath}/associations/${associationId}`;

    const response = await this.httpClient.get<Association>(url).toPromise();

    return response;
  }

  public async getAssociations(filters: any[]): Promise<Association[]> {
    const url = `${this.basePath}/associations`;

    const bankFilter =  this.getFilterByName(filters, 'banqueId') as number;
    const categorieFilter = this.getFilterByName(filters, 'categorie') as string;
    const activeFilter = this.getFilterByName(filters, 'active') as boolean;
    const currentPartnershipLevelFilter = this.getFilterByName(filters, 'niveauParteneriatActuel') as NiveauParteneriatAssociation;
    const newPartnershipLevelFilter = this.getFilterByName(filters, 'niveauParteneriatNouveau') as NiveauParteneriatAssociation;
    

    const params = this.createParams(
      categorieFilter,
      bankFilter,
      activeFilter,
      currentPartnershipLevelFilter,
      newPartnershipLevelFilter,
      );

    const response = await this.httpClient.get<AssociationsResponse>(url, { params }).toPromise();
    return response?.data || [];
  }

  private getFilterByName(filters: { key: string, value: string | boolean | number }[], filterName: string): string | boolean | number | null {
    const filter = filters.find(f => f.key === filterName);
    if (filter) {
      return filter.value;
    }
    return null;
  }
}
