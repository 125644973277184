import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { ConfigKeys } from '../../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../../shared/services/config.service';
import { AmsRouteProvider } from '../../../services/ams-route.provider';
import { StatistiquesVersion } from '../interfaces/statistiques-version.interface';
import { NiveauParteneriatAssociation } from '../../associations/types';

@Injectable({
  providedIn: 'root',
})
export class StatistiquesService {
  private basePath = 'http://localhost:3333/api';

  constructor(
    public httpClient: HttpClient,
    private readonly store$: Store<any>,
    private readonly config: ConfigService,
    private readonly routerService: AmsRouteProvider
  ) {
    this.basePath = config.get(ConfigKeys.API_BASE_PATH);
  }

  public async getNbAssocViaOutilTrimestre(
    periode: string | null = null,
    annee: number | null = null,
    banqueId: number | null = null,
    parnershipLevel: NiveauParteneriatAssociation | null = null,
    categorie: string | null = null
  ): Promise<any> {
    const params = this.createParams(
      periode,
      annee,
      banqueId,
      categorie,
      parnershipLevel
    );
    const url = `${this.basePath}/${
      this.routerService.getRoutes().nbAssocViaOutilTrimestre
    }`;

    const response = await this.httpClient.get(url, { params }).toPromise();
    return response;
  }

  public async getNbAssocViaOutil(
    annee: number | null = null,
    banqueId: number | null = null,
    parnershipLevel: NiveauParteneriatAssociation | null = null,
    categorie: string | null = null
  ): Promise<any> {
    const params = this.createParams(
      null,
      annee,
      banqueId,
      categorie,
      parnershipLevel
    );
    const url = `${this.basePath}/${
      this.routerService.getRoutes().nbAssocViaOutil
    }`;

    const response = await this.httpClient.get(url, { params }).toPromise();

    return response;
  }

  public async getNbAssocAyant(
    periode: string | null = null,
    annee: number | null = null,
    banqueId: number | null = null,
    parnershipLevel: NiveauParteneriatAssociation | null = null,
    categorie: string | null = null
  ): Promise<any> {
    const params = this.createParams(
      periode,
      annee,
      banqueId,
      categorie,
      parnershipLevel
    );
    const url = `${this.basePath}/${
      this.routerService.getRoutes().nbAssocAyant
    }`;

    const response = await this.httpClient.get(url, { params }).toPromise();

    return response;
  }

  public async getNbAssocIndValides(
    periode: string | null = null,
    annee: number | null = null,
    banqueId: number | null = null,
    parnershipLevel: NiveauParteneriatAssociation | null = null,
    categorie: string | null = null
  ): Promise<any> {
    const params = this.createParams(
      periode,
      annee,
      banqueId,
      categorie,
      parnershipLevel
    );
    const url = `${this.basePath}/${
      this.routerService.getRoutes().nbAssocIndValides
    }`;

    const response = await this.httpClient.get(url, { params }).toPromise();

    return response;
  }

  public async getNbAssociationsIndNonRecus(
    periode: string | null = null,
    annee: number | null = null,
    banqueId: number | null = null,
    parnershipLevel: NiveauParteneriatAssociation | null = null,
    categorie: string | null = null
  ): Promise<any> {
    const params = this.createParams(
      periode,
      annee,
      banqueId,
      categorie,
      parnershipLevel
    );
    const url = `${this.basePath}/${
      this.routerService.getRoutes().nbAssociationsIndNonRecus
    }`;

    const response = await this.httpClient.get(url, { params }).toPromise();

    return response;
  }

  public async getStatistiquesVersions(
    banqueId: number
  ): Promise<StatistiquesVersion[]> {
    const response = await this.getBanqueVersions(banqueId);
    const versionDictionary = response?.result;
    const versionData: StatistiquesVersion[] = [];

    if (versionDictionary) {
      for (const key in versionDictionary) {
        if (versionDictionary.hasOwnProperty(key)) {
          versionData.push({ version: key, count: versionDictionary[key] });
        }
      }
    }

    return of(
      versionData.sort((a, b) =>
        a.version > b.version ? 1 : b.version > a.version ? -1 : 0
      )
    ).toPromise();
  }

  private async getBanqueVersions(banqueId: number): Promise<any> {
    const url = `${this.basePath}/banques/${banqueId}/get-stats-version`;

    const response = await this.httpClient.get(url).toPromise();

    return response;
  }

  private createParams(
    periode: string | null = null,
    annee: number | null = null,
    banqueId: number | null = null,
    categorie: string | null = null,
    partnershipLevel: NiveauParteneriatAssociation | null = null
  ) {
    return {
      ...(periode && { periode }),
      ...(annee && { annee }),
      ...(banqueId && { banqueId }),
      ...(partnershipLevel === null ? {} : { niveauParteneriat: partnershipLevel }),
      ...(categorie && { categorie }),
    };
  }
}
