import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
  // eslint-disable-next-line
  selector: '[restrictSpecialCharacters]',
})
export class RestrictSpecialCharactersDirective {
  inputElement: ElementRef;
  
  // eslint-disable-next-line
  @Input('restrictSpecialCharacters') restrictSpecialCharacters: string;

  constructor(el: ElementRef) {
    this.inputElement = el;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    const e = <KeyboardEvent>event;
    return (
      ['/', ':', '\\', '=', '+', '@', 'Tab', 'Enter'].indexOf(e.key) === -1
    );
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    const regex = /[\/:\\=\+@\t\r]/;
    const e = <ClipboardEvent>event;
    const pasteData = e.clipboardData.getData('text/plain');
    const textContainsSpecialCharacter = regex.test(pasteData);
    return !textContainsSpecialCharacter;
  }
}
