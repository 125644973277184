import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { FilterTypesEnum, TcConfigTypes, TcDataProviderType } from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import { TcTranslateService } from '@tc/core';
import { ITcRestApiRoutesProvider } from '@tc/rest-api';
import { DEFAULT_GRID_HEADER_HEIGHT, DEFAULT_GRID_ROW_HEIGHT } from '../../../../../shared/utils/constants';

import { dateCellRenderer } from '../../../../../shared/utils/format.utils';

@Component({
  selector: 'app-received-delivery-note-articles-grid',
  templateUrl: './received-delivery-note-articles-grid.component.html',
  styleUrls: ['./received-delivery-note-articles-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReceivedDeliveryNoteArticlesGridComponent
  extends TcSmartGridComponent
  implements OnInit
{
  @Input() receivedBLId: number;

  storeKey = 'received-delivery-note-articles-grid';

  constructor(
    store$: Store<any>,
    private readonly routeProvider: ITcRestApiRoutesProvider,
    private readonly translate: TcTranslateService
  ) {
    super(store$);
  }

  async ngOnInit() {
    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'received-delivery-note-articles-grid',
      gridOptions: {
        headerHeight: DEFAULT_GRID_HEADER_HEIGHT,
        rowHeight: DEFAULT_GRID_ROW_HEIGHT
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.RestApi,
        dataSet: this.routeProvider.getRoutes().listArticlesRecu,
        fields: '',
        filter: {
          filters: [
            {
              key: 'blId',
              value: this.receivedBLId.toString(),
              filterType: FilterTypesEnum.Equal,
            },
          ],
        },
      },
      columns: [
        {
          field: 'codeArticle',
          headerClass: 'ag-right-aligned-header',
          cellClass: 'text-align-right',
          width: 150,
          maxWidth: 150,
          suppressAutoSize: true,
          resizable: false
        },
        {
          field: 'libelle',
          resizable: false
        },
        {
          field: 'nbUnites',
          headerClass: 'ag-right-aligned-header',
          cellClass: 'text-align-right',
          width: 100,
          maxWidth: 100,
          suppressAutoSize: true,
          resizable: false
        },
        {
          field: 'qteUnite',
          headerClass: 'ag-right-aligned-header',
          cellClass: 'text-align-right',
          width: 100,
          maxWidth: 100,
          suppressAutoSize: true,
          resizable: false
        },
        {
          field: 'quantite',
          headerClass: 'ag-right-aligned-header',
          cellClass: 'text-align-right',
          width: 100,
          maxWidth: 100,
          suppressAutoSize: true,
          resizable: false
        },
        {
          field: 'dateLimite',
          headerClass: 'ag-right-aligned-header',
          cellClass: 'text-align-right',
          width: 100,
          maxWidth: 100,
          suppressAutoSize: true,
          cellRenderer: (params) => dateCellRenderer(params?.value),
          resizable: false
        },
        {
          field: 'numLot',
          width: 150,
          maxWidth: 150,
          resizable: false
        },
        {
          field: 'libelleComplementaire',
          resizable: false
        },
      ],
    };

    super.ngOnInit();
  }
}
