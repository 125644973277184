import { Injectable } from '@angular/core';
import { TcTranslateService } from '@tc/core';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
} from '@tc/data-store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter} from 'rxjs/operators';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { select, Store } from '@ngrx/store';
import { hasValue } from '@tc/utils';
import { TcBreezeService } from '@tc/breeze';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  /**
   *  Data store
   */
  dataStore$: Observable<NgRxTcDataState>;

  TABLE_STYLE = 'table_style';
  SUBTITLE_STYLE = 'subtitle_style';

  constructor(
    private readonly store$: Store<any>,
    private readonly translate: TcTranslateService,
    private breeze: TcBreezeService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  buildPdf(pdfContent: any, fileName: string) {
    const pdfDef = {
      pageSize: 'A4',
      pageMargins: [10, 10, 10, 50],
      content: pdfContent,
      footer: (currentPage) => {
        return {
          text: currentPage.toString(),
          alignment: 'left',
          margin: [20, 10, 10, 10],
        };
      },
      styles: {
        [this.SUBTITLE_STYLE]: {
          fontSize: 16,
          bold: true,
          margin: [0, 5, 0, 5],
        },
        [this.TABLE_STYLE]: {
          margin: [0, 10, 0, 10],
          fontSize: 10,
        },
      },
    };
    pdfMake.createPdf(pdfDef).download(fileName);
  }

  transformHTML(value: any): string {
    const temp = document.createElement('div');
    temp.innerHTML = value;
    return temp.textContent || temp.innerText || '';
  }

  // allow breaking after any character to be sure that text will be always inside the cell
  wrapText(text: string) {
    return text?.split('').join('\u200b');
  }
}
