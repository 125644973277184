import { IndicateursEtat } from "../interfaces/indicateurs-etat.interface";
import { Trimestre } from "../typings/trimestre.enum";

const overideEndingDate = (intervalModificationTicadi: number, beginDate: Date, endingDate: Date) => {
    if (!!intervalModificationTicadi && intervalModificationTicadi > 0)
    { 
        const newDate = new Date(beginDate);
        newDate.setDate(beginDate.getDate() + intervalModificationTicadi);
        endingDate = newDate
    }
    return endingDate;
}

export const canEditTicadiIndicator = (indicator: IndicateursEtat, intervalModificationTicadi: number): boolean => {
    let beginDate: Date | null;
    let endDate: Date | null;
    const now = Date.now();

    switch(indicator.trimestreIndicateurs) {
      case Trimestre.T1: {
        beginDate = new Date(indicator.annee, 3, 1);
        endDate = new Date(indicator.annee, 4, 12);
        endDate = overideEndingDate(intervalModificationTicadi, beginDate, endDate);
        return now >= beginDate.getTime() && now < endDate.getTime();
      }
      case Trimestre.T2: {
        beginDate = new Date(indicator.annee, 6, 1);
        endDate = new Date(indicator.annee, 7, 12);
        endDate = overideEndingDate(intervalModificationTicadi, beginDate, endDate);
        return now >= beginDate.getTime() && now < endDate.getTime();
      }
      case Trimestre.T3: {
        beginDate = new Date(indicator.annee, 9, 1);
        endDate = new Date(indicator.annee, 10, 12);
        endDate = overideEndingDate(intervalModificationTicadi, beginDate, endDate);
        return now >= beginDate.getTime() && now < endDate.getTime();
      }
      case Trimestre.T4:
      case Trimestre.Annee: {
        beginDate = new Date(indicator.annee + 1, 0, 1);
        endDate = new Date(indicator.annee + 1, 1, 11);
        endDate = overideEndingDate(intervalModificationTicadi, beginDate, endDate);
        // FFBA-194
        if (indicator.annee === 2021) {
          // BA devront saisir et valider dans AMS jusqu'au 02 march 2022
          endDate = new Date(2022, 2, 2);
        }
        
        // FFBA-514
        if (indicator.annee === 2023) {
          // BA devront saisir et valider dans AMS jusqu'au 25 march 2024 inclusive
          endDate = new Date(2024, 2, 26);
        }

        return now >= beginDate.getTime() && now < endDate.getTime();
      }
      default: return false;
    }
  }