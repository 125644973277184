import {  Component, ElementRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { AgEditorComponent } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";
import { FormBuilder, FormGroup } from "@angular/forms";

export interface ComboEditorOption {
    label: string;
    value: string;
}

@Component({
    selector: 'tc-grid-combo-editor',
    templateUrl: './tc-grid-combo-editor.component.html',
    styleUrls: ['./tc-grid-combo-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TcGridComboEditorComponent implements AgEditorComponent {

    @ViewChild('input') input: ElementRef;
    
    options: ComboEditorOption[] = [];
    form: FormGroup;

    constructor(private readonly _fb: FormBuilder) {}

    public agInit(params: ICellEditorParams): void {
        this.options = params['possibleValues'];

        this.form = this._fb.group({
            value: params.value
        });
    }

    getValue() {
        return this.form.getRawValue().value
    }
}