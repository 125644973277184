import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssociationsGridComponent } from './components/dumb/associations-grid/associations-grid.component';
import { TcCoreModule } from '@tc/core';
import { AssociationsFilterComponent } from './components/dumb/associations-filter/associations-filter.component';
import { AssociationsDetailsComponent } from './components/dumb/associations-details/associations-details.component';
import { TcButtonsModule } from '@tc/buttons';
import { TcSmartFormModule } from '@tc/smart-form';
import { TcDialogModule } from '@tc/dialog';
import { EffectsModule } from '@ngrx/effects';
import { AssociationsEffects } from './store/associations.effects';
import { AssociationsService } from './services/associations.service';
import { StoreModule } from '@ngrx/store';
import { associationsReducer } from './store/associations.reducer';
import { SharedModule } from '../../shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';

const components = [
  AssociationsFilterComponent,
  AssociationsGridComponent,
  AssociationsDetailsComponent,
];

const services = [AssociationsService];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcButtonsModule,
    TcSmartFormModule,
    TcCoreModule,
    TcDialogModule,
    EffectsModule.forFeature([AssociationsEffects]),
    StoreModule.forFeature('associations', associationsReducer),
    SharedModule,
    AgGridModule,
  ],
  providers: [...services],
  exports: [...components],
})
export class AssociationsModule {}
