import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-tc-formly-field-button',
  templateUrl: './tc-formly-field-button.component.html',
  styleUrls: ['./tc-formly-field-button.component.scss'],
})
export class TcFormlyFieldButtonComponent extends FieldType implements OnInit {
  matIcon = null;
  faIcon = null;
  ionIcon = null;
  isDisabled = false;

  ngOnInit() {
    this.matIcon = this.props.matIcon || this.matIcon;
    this.faIcon = this.props.faIcon || this.faIcon;
    this.ionIcon = this.props.ionIcon || this.ionIcon;
    this.isDisabled = this.props.disabled || this.isDisabled;
  }

  onClick($event) {
    if (this.props.onClick) {
      this.props.onClick($event);
    }
  }
}
