import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { TcTranslateService } from '@tc/core';
import { updateTcSmartFormCurrentModel } from '@tc/smart-form';
import * as R from 'ramda';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfigService } from '../../../../../shared/services/config.service';
import { DEFAULT_INTERVAL_MODIFICATION } from '../../../../shared/utils/constants';
import { AssociationCategorie } from '../../../associations/types';
import { canEditTicadiIndicator } from '../../helpers/indicators-validation-helpers';
import { AmsIndicatorTableModel } from '../../interfaces/ams-indicator-table-model.interface';
import { AmsIndicatorTableItem } from '../../interfaces/ams-indicator-table.interface';
import { GetIndicateursEtatDetailsResponse } from '../../interfaces/get-indicateurs-etat-details-response.interface';
import { IndicateursEtat } from '../../interfaces/indicateurs-etat.interface';
import { Periode } from '../../interfaces/periode.interface';
import { IndicatorsService } from '../../services/indicators.service';
import {
  setIndicatorToUpdate,
  setTicadiIndicatorsToModify,
  setTicadiIndicatorsValuesToUpdate,
} from '../../store/indicators.actions';
import { getIndicatorsAssociation } from '../../store/indicators.selectors';
import { IndicatorKeys } from '../../typings/indicator-keys.enum';
import { IndicatorType } from '../../typings/indicator-type.enum';
import { StatusValidation } from '../../typings/status-validation.enum';
import { Trimestre } from '../../typings/trimestre.enum';
import {
  INDICATORS_MODIFIABLE_DAYS_COUNT_CONFIG_KEY,
  INDICATOR_DETAILS_MODIFY_TRIMESTRE_BUTTON_ID,
  INDICATOR_DETAILS_MODIFY_YEAR_BUTTON_ID,
  INDICATOR_DETAILS_SAVE_BUTTON_ID,
  INDICATOR_DETAILS_VALIDATE_BUTTON_ID,
  INTERVAL_MODIFICATION_TICADI,
} from '../../utils/constants';
import {
  getPeriodFromDate,
  getTrimesterAndYearFromPeriodString,
  previousPeriod,
} from '../../utils/helpers';

@Component({
  selector: 'app-ams-indicator-table',
  templateUrl: './ams-indicator-table.component.html',
  styleUrls: ['./ams-indicator-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AmsIndicatorTableComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  @Input() indicator: IndicateursEtat;

  @Output() toggleIndicatorInitialDetails = new EventEmitter<{
    indicators: IndicateursEtat[];
    selectedTrimestre: Trimestre;
    isEditMode: boolean;
  }>();

  public associationId: number;
  public currentTrimestre: string;

  // Status Bindings
  public t1Status = '';
  public t2Status = '';
  public t3Status = '';
  public t4Status = '';
  public anneeStatus = '';

  public t1StatusEnum: StatusValidation | null = null;
  public t2StatusEnum: StatusValidation | null = null;
  public t3StatusEnum: StatusValidation | null = null;
  public t4StatusEnum: StatusValidation | null = null;
  public anneeStatusEnum: StatusValidation | null = null;

  // Editable Bindings
  public t1Editable = false;
  public t2Editable = false;
  public t3Editable = false;
  public t4Editable = false;
  public anneeEditable = false;
  public commentsEditable = false;

  // Visibility bindings
  public anneeRowsVisible = true;
  public isIndicatorTicadiLayout = false;

  // Details Icon Visibility bindings
  public t1ViewIconVisible = false;
  public t2ViewIconVisible = false;
  public t3ViewIconVisible = false;
  public t4ViewIconVisible = false;
  public anneeViewIconVisible = false;

  model: AmsIndicatorTableModel;
  comments: string;
  indicateurs: IndicateursEtat[] = [];
  // Map to keep track of incorrect values (Ex: beneficiaires values require full integers)
  incorrectValues = {};

  subscription = new Subscription();

  private modifiableDaysCount = DEFAULT_INTERVAL_MODIFICATION;
  private intervalModificationTicadi = 0;
  private isTicadiIndicateurs = false;

  constructor(
    store$: Store<any>,
    private indicatorsService: IndicatorsService,
    private readonly configService: ConfigService,
    private readonly translate: TcTranslateService
  ) {
    super(store$);
    this.modifiableDaysCount = configService.get(
      INDICATORS_MODIFIABLE_DAYS_COUNT_CONFIG_KEY
    );

    this.intervalModificationTicadi =
      configService.get(INTERVAL_MODIFICATION_TICADI) || 0;
  }

  async ngOnInit() {
    this.currentTrimestre = this.indicator.trimestreIndicateurs;
    this.associationId = await this.store$
      .select(getIndicatorsAssociation)
      .pipe(take(1))
      .toPromise();

    this.model = this.initializeModel();
    const { associationId, annee, type } = this.indicator;

    // Get data directly from HTTP from component for a better UX when re-loading this view after initially closing it
    // This enables initial values to always be 0
    let response: GetIndicateursEtatDetailsResponse | null = null;
    if (type.toUpperCase() === IndicatorType.BA) {
      response = await this.indicatorsService.getYearIndicatorById(
        associationId,
        annee,
        this.currentTrimestre
      );
    } else {
      this.isTicadiIndicateurs = true;
      response = await this.indicatorsService.getYearIndicateursByIdForTicadi(
        associationId,
        annee,
        this.currentTrimestre
      );
    }
    this.indicateurs = response?.data || [];

    if (this.indicator?.type.toUpperCase() === IndicatorType.BA) {
      this.setSimpleIndicators(this.indicateurs);
    } else {
      await this.setIndicatorsTicadi(this.indicateurs);
      this.isIndicatorTicadiLayout = true;
    }
  }

  ngOnDestroy(): void {
    this.indicateurs = [];
    this.subscription?.unsubscribe();
  }

  // #region Set Indicators Ticadi

  private async setIndicatorsTicadi(stateIndicators: IndicateursEtat[]) {
    if ((stateIndicators.length || 0) > 0) {
      let periode = getPeriodFromDate(new Date());
      periode = previousPeriod(periode);
      let indicator = stateIndicators.find(
        (i) =>
          i.trimestreIndicateurs.toLowerCase() ===
          periode.trimestre.toLowerCase()
      );

      // In case the period was not on the same year as the requested data, load the period year indicators too
      if (!indicator) {
        const response =
          await this.indicatorsService.getYearIndicateursByIdForTicadi(
            this.indicator.associationId,
            periode.year,
            periode.trimestre
          );
        indicator = response.data?.find(
          (i) =>
            i.trimestreIndicateurs.toLowerCase() ===
            periode.trimestre.toLowerCase()
        );
      }
      if (indicator) {
        const canEditIndicateur = canEditTicadiIndicator(
          indicator,
          this.intervalModificationTicadi
        );

        if (
          canEditIndicateur &&
          indicator.trimestreIndicateurs === Trimestre.T4
        ) {
          this.anneeEditable = true;
        }
        if (
          indicator.categorieAssociation.toLowerCase() ===
          AssociationCategorie.Categorie1.toLowerCase()
        ) {
          this.anneeRowsVisible = true;
        } else {
          this.anneeRowsVisible = false;
        }
        this.setModifyButtonsEnabled(canEditIndicateur, this.anneeEditable);
      }

      const prevPeriod = previousPeriod(periode);
      this.store$.dispatch(
        setTicadiIndicatorsToModify({
          indicators: stateIndicators,
          trimestre: prevPeriod.trimestre,
        })
      );

      this.loadModelDataTicadi(stateIndicators);
      this.comments = this.model.comments || '';

      // Make a copy of the indicateur so that the user can instantly validate or save it
      this.setTicadiFormValuesForSave();
    }
  }

  private setModifyButtonsEnabled(
    canEditTrimestre: boolean,
    canEditYear: boolean
  ) {
    this.store$.dispatch(
      updateTcSmartFormCurrentModel({
        storeKey: INDICATOR_DETAILS_MODIFY_TRIMESTRE_BUTTON_ID,
        invalid: !canEditTrimestre,
        currentModel: null,
      })
    );
    this.store$.dispatch(
      updateTcSmartFormCurrentModel({
        storeKey: INDICATOR_DETAILS_VALIDATE_BUTTON_ID,
        invalid: !canEditTrimestre,
        currentModel: null,
      })
    );
    this.store$.dispatch(
      updateTcSmartFormCurrentModel({
        storeKey: INDICATOR_DETAILS_MODIFY_YEAR_BUTTON_ID,
        invalid: !canEditYear,
        currentModel: null,
      })
    );
  }

  private loadModelDataTicadi(stateIndicators: IndicateursEtat[]) {
    const indicators = stateIndicators.filter(
      (i) =>
        i.type.toUpperCase() === IndicatorType.TICADI_BA.toUpperCase() ||
        i.type.toUpperCase() === IndicatorType.BA.toUpperCase()
    );

    for (const indicator of indicators) {
      switch (indicator.trimestreIndicateurs) {
        case Trimestre.T1:
          {
            this.setTrimestreData(indicator, Trimestre.T1);
            this.t1Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t1StatusEnum = indicator.statutValidation;

            // Enabled state
            this.t1Editable = false;

            // View icon
            this.t1ViewIconVisible =
              indicator.statutValidation.toUpperCase() !==
              StatusValidation.NonRecu.toUpperCase();
          }
          break;
        case Trimestre.T2:
          {
            this.setTrimestreData(indicator, Trimestre.T2);
            this.t2Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t2StatusEnum = indicator.statutValidation;

            // Enabled state
            this.t2Editable = false;

            // View icon
            this.t2ViewIconVisible =
              indicator.statutValidation.toUpperCase() !==
              StatusValidation.NonRecu.toUpperCase();
          }
          break;
        case Trimestre.T3:
          {
            this.setTrimestreData(indicator, Trimestre.T3);
            this.t3Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t3StatusEnum = indicator.statutValidation;

            // Enabled state
            this.t3Editable = false;

            // View icon
            this.t3ViewIconVisible =
              indicator.statutValidation.toUpperCase() !==
              StatusValidation.NonRecu.toUpperCase();
          }
          break;
        case Trimestre.T4:
          {
            this.setTrimestreData(indicator, Trimestre.T4);
            this.t4Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t4StatusEnum = indicator.statutValidation;

            // Enabled state
            this.t4Editable = false;

            // View icon
            this.t4ViewIconVisible =
              indicator.statutValidation.toUpperCase() !==
              StatusValidation.NonRecu.toUpperCase();
          }
          break;
        case Trimestre.Annee:
          {
            // Check if we have a BA for trimester 4 (old passerelle indicator)
            const indicatorBa = indicators.find(
              (i) =>
                i.type.toUpperCase() === IndicatorType.BA.toUpperCase() &&
                i.trimestreIndicateurs === Trimestre.T4
            );

            if (indicatorBa) {
              this.setYearData(indicatorBa);
              this.anneeStatus = this.translate.instant(
                `ams-indicator-table.status.${indicatorBa.statutValidation.toLowerCase()}`
              );
              this.anneeStatusEnum = indicator.statutValidation;
              // View icon
              this.anneeViewIconVisible =
                indicatorBa.statutValidation.toUpperCase() !==
                StatusValidation.NonRecu.toUpperCase();
            } else {
              this.setYearData(indicator);
              this.anneeStatus = this.translate.instant(
                `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
              );
              this.anneeStatusEnum = indicator.statutValidation;
              // View icon
              this.anneeViewIconVisible =
                indicator.statutValidation.toUpperCase() !==
                StatusValidation.NonRecu.toUpperCase();
            }
            // Enabled state
            this.anneeEditable = false;
          }
          break;
        default:
          break;
      }
    }
  }

  private setYearData(indicator: IndicateursEtat) {
    const { indVolumeAnnee, indBeneficiairesAnnee } = indicator;

    this.model.volumesDistributed[Trimestre.Annee] =
      indVolumeAnnee?.poidsNetT.toFixed(2) ?? 0.0;

    // Beneficiaires data
    this.model.beneficiariesHouseholds[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbFoyersInscrits ?? 0;
    this.model.beneficiariesRights[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnesInscrites ?? 0;
    this.model.beneficiariesPassages[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnesAidees ?? 0;

    // Age data -> FSE+
    this.model.beneficiariesAge0to17[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnes0a17ans ?? 0;
    this.model.beneficiariesAge18to29[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnes18a29ans ?? 0;
    this.model.beneficiariesAge30to64[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnes30a64ans ?? 0;
    this.model.beneficiariesAgeOver65Fse[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnesPlus65ansFse ?? 0;

    // Age data
    this.model.beneficiariesAge0to3[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnes0a3ans ?? 0;
    this.model.beneficiariesAge4to14[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnes4a14ans ?? 0;
    this.model.beneficiariesAge15to25[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnes15a25ans ?? 0;
    this.model.beneficiariesAge26to64[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnes26a64ans ?? 0;
    this.model.beneficiariesAgeOver65[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbPersonnesPlus65ans ?? 0;

    // Genre data
    this.model.beneficiariesSexMale[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbHommes ?? 0;
    this.model.beneficiariesSexFemale[Trimestre.Annee] =
      indBeneficiairesAnnee?.nbFemmes ?? 0;
  }

  private setTrimestreData(indicator: IndicateursEtat, trimestre: Trimestre) {
    const { indVolumeTrimestre, indBeneficiairesTrimestre } = indicator;
    this.model.volumesDistributed[trimestre] =
      indVolumeTrimestre?.poidsNetT?.toFixed(2) ?? 0.0;

    this.model.beneficiariesHouseholds[trimestre] =
      indBeneficiairesTrimestre?.nbFoyersInscrits ?? 0;
    this.model.beneficiariesRights[trimestre] =
      indBeneficiairesTrimestre?.nbPersonnesInscrites ?? 0;
    this.model.beneficiariesPassages[trimestre] =
      indBeneficiairesTrimestre?.nbPersonnesAidees ?? 0;
  }

  //#endregion

  private setSimpleIndicators(stateIndicators: IndicateursEtat[]) {
    if (stateIndicators.length > 0) {
      // Compute period and modifiable until
      let periode = getPeriodFromDate(new Date());

      const trimestreAndYearPeriode = getTrimesterAndYearFromPeriodString(
        stateIndicators[0]?.periodeString
      )?.trimestre;
      const modifiableUntil = this.getModifiableUntil(periode);

      periode = previousPeriod(periode);

      let indicateur = stateIndicators.find(
        (i) =>
          i.trimestreIndicateurs.toLowerCase() ===
          periode.trimestre.toLowerCase()
      );
      const canEditIndicateur =
        indicateur !== null &&
        ((indicateur?.trimestreIndicateurs !== Trimestre.T4 &&
          indicateur?.annee === new Date().getFullYear()) ||
          (indicateur?.trimestreIndicateurs === Trimestre.T4 &&
            indicateur.annee === new Date().getFullYear() - 1)) &&
        new Date().getTime() <= modifiableUntil.getTime();

      const isSaveButtonEnabled =
        canEditIndicateur &&
        indicateur?.statutValidation !== StatusValidation.Valide;

      this.setSaveButtonEnabled(isSaveButtonEnabled, canEditIndicateur);

      if (indicateur) {
        if (!canEditIndicateur)
          indicateur = stateIndicators.find(
            (i) =>
              i.trimestreIndicateurs.toLowerCase() ===
              trimestreAndYearPeriode?.toLowerCase()
          );

        if (indicateur != null) {
          if (
            indicateur.categorieAssociation.toLowerCase() ===
            AssociationCategorie.Categorie1.toLowerCase()
          ) {
            this.anneeRowsVisible = true;
          } else {
            this.anneeRowsVisible = false;
          }

          if (
            indicateur.categorieAssociation === AssociationCategorie.Categorie1
          ) {
            this.loadModelDataSimpleCategory1(
              stateIndicators,
              canEditIndicateur,
              Trimestre[indicateur.trimestreIndicateurs.toUpperCase()]
            );
          } else {
            this.loadModelDataSimpleCategory2(
              stateIndicators,
              canEditIndicateur,
              Trimestre[indicateur.trimestreIndicateurs.toUpperCase()]
            );
          }
          this.comments = this.model.comments || '';
        }

        if (canEditIndicateur) {
          // Make a copy of the indicateur so that the user can instantly validate or save it
          this.setFormValuesForSave();
        }
      }
    }
  }

  private getModifiableUntil(period: Periode): Date {
    const startDate = period.getStartDate();
    let modifiableUntil = new Date(
      startDate.setDate(startDate.getDate() + this.modifiableDaysCount)
    );

    // FFBA-514
    if (period.year === 2023) {
      // BA devront saisir et valider dans AMS jusqu'au 25 march 2024 inclusive
      if (period.trimestre === Trimestre.T4) {
        modifiableUntil = new Date(2024, 2, 26);
      }
    }

    return modifiableUntil;
  }

  private setSaveButtonEnabled(
    saveButtonEnabled: boolean,
    validateButtonEnabled: boolean
  ) {
    this.store$.dispatch(
      updateTcSmartFormCurrentModel({
        storeKey: INDICATOR_DETAILS_SAVE_BUTTON_ID,
        invalid: !saveButtonEnabled,
        currentModel: null,
      })
    );
    this.store$.dispatch(
      updateTcSmartFormCurrentModel({
        storeKey: INDICATOR_DETAILS_VALIDATE_BUTTON_ID,
        invalid: !validateButtonEnabled,
        currentModel: null,
      })
    );
  }

  private loadModelDataSimpleCategory2(
    stateIndicators: IndicateursEtat[],
    canEditIndicator: boolean = false,
    trimestre: Trimestre | null = null
  ) {
    for (const indicator of stateIndicators) {
      const period = indicator.trimestreIndicateurs;

      switch (period) {
        case Trimestre.T1:
          {
            this.setTrimestreData(indicator, Trimestre.T1);
            this.t1Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t1StatusEnum = indicator.statutValidation;
            this.t1Editable = canEditIndicator && trimestre === Trimestre.T1;
          }
          break;
        case Trimestre.T2:
          {
            this.setTrimestreData(indicator, Trimestre.T2);
            this.t2Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t2StatusEnum = indicator.statutValidation;
            this.t2Editable = canEditIndicator && trimestre === Trimestre.T2;
          }
          break;
        case Trimestre.T3:
          {
            this.setTrimestreData(indicator, Trimestre.T3);
            this.t3Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t3StatusEnum = indicator.statutValidation;
            this.t3Editable = canEditIndicator && trimestre === Trimestre.T3;
          }
          break;
        case Trimestre.T4:
          {
            // Trimestre 4 data
            this.setTrimestreData(indicator, Trimestre.T4);
            this.t4Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t4StatusEnum = indicator.statutValidation;
            this.t4Editable = canEditIndicator && trimestre === Trimestre.T4;

            // Year Data
            this.setYearData(indicator);
            this.anneeStatus = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.anneeStatusEnum = indicator.statutValidation;
            this.anneeEditable = this.t4Editable;
          }
          break;
      }

      this.commentsEditable = canEditIndicator;
      this.model.comments = indicator.associationCommentaire[indicator.annee];
    }
  }

  private loadModelDataSimpleCategory1(
    stateIndicators: IndicateursEtat[],
    canEditIndicator: boolean = false,
    trimestre: Trimestre | null = null
  ) {
    for (const indicator of stateIndicators) {
      const period = indicator.trimestreIndicateurs;

      // Set isNonRecu for period
      switch (period) {
        case Trimestre.T1:
          {
            this.setTrimestreData(indicator, Trimestre.T1);

            this.t1Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t1StatusEnum = indicator.statutValidation;
            this.t1Editable = canEditIndicator && trimestre === Trimestre.T1;
          }
          break;
        case Trimestre.T2:
          {
            this.setTrimestreData(indicator, Trimestre.T2);

            this.t2Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t2StatusEnum = indicator.statutValidation;
            this.t2Editable = canEditIndicator && trimestre === Trimestre.T2;
          }
          break;
        case Trimestre.T3:
          {
            this.setTrimestreData(indicator, Trimestre.T3);

            this.t3Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t3StatusEnum = indicator.statutValidation;
            this.t3Editable = canEditIndicator && trimestre === Trimestre.T3;
          }
          break;
        case Trimestre.T4:
          {
            // T4 Data
            this.setTrimestreData(indicator, Trimestre.T4);
            this.t4Status = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.t4StatusEnum = indicator.statutValidation;
            this.t4Editable = canEditIndicator && trimestre === Trimestre.T4;

            // Year Data
            this.setYearData(indicator);
            this.anneeStatus = this.translate.instant(
              `ams-indicator-table.status.${indicator.statutValidation.toLowerCase()}`
            );
            this.anneeStatusEnum = indicator.statutValidation;
            this.anneeEditable = this.t4Editable;
          }
          break;
      }
      this.commentsEditable = canEditIndicator;
      this.model.comments = indicator.associationCommentaire[indicator.annee];
    }
  }

  private initializeModel(): AmsIndicatorTableModel {
    return {
      volumesDistributed: this.initModelItem(
        this.indicator.categorieAssociation.toLowerCase() ===
          AssociationCategorie.Categorie2.toLowerCase()
          ? IndicatorKeys.VolumeDistributedCategory2
          : IndicatorKeys.VolumeDistributed,
        true
      ),
      beneficiariesHouseholds: this.initModelItem(
        IndicatorKeys.BeneficiariesRegisteredHouseholds,
        true
      ),
      beneficiariesRights: this.initModelItem(
        IndicatorKeys.BeneficiariesRegisteredWithRights,
        true
      ),
      beneficiariesPassages: this.initModelItem(
        IndicatorKeys.BeneficiariesPassages,
        true
      ),
      beneficiariesAge0to17: this.initModelItem(
        IndicatorKeys.BeneficiariesAge0to17
      ),
      beneficiariesAge18to29: this.initModelItem(
        IndicatorKeys.BeneficiariesAge18to29
      ),
      beneficiariesAge30to64: this.initModelItem(
        IndicatorKeys.BeneficiariesAge30to64
      ),
      beneficiariesAgeOver65Fse: this.initModelItem(
        IndicatorKeys.BeneficiariesAgeOver65Fse
      ),
      beneficiariesAge0to3: this.initModelItem(
        IndicatorKeys.BeneficiariesAge0to3
      ),
      beneficiariesAge4to14: this.initModelItem(
        IndicatorKeys.BeneficiariesAge4to14
      ),
      beneficiariesAge15to25: this.initModelItem(
        IndicatorKeys.BeneficiariesAge15to25
      ),
      beneficiariesAge26to64: this.initModelItem(
        IndicatorKeys.BeneficiariesAge26to64
      ),
      beneficiariesAgeOver65: this.initModelItem(
        IndicatorKeys.BeneficiariesAgeOver65
      ),
      beneficiariesSexMale: this.initModelItem(IndicatorKeys.BeneficiariesMale),
      beneficiariesSexFemale: this.initModelItem(
        IndicatorKeys.BeneficiariesFemale
      ),
      comments: '',
    };
  }

  private initModelItem(
    indicatorKey,
    trimestreRow: boolean = false
  ): AmsIndicatorTableItem {
    return {
      indicatorKey,
      t1: trimestreRow ? 0 : null,
      t2: trimestreRow ? 0 : null,
      t3: trimestreRow ? 0 : null,
      t4: trimestreRow ? 0 : null,
      annee: 0,
    };
  }

  onChange() {
    if (this.isTicadiIndicateurs) {
      this.setTicadiFormValuesForSave();
    } else {
      this.setFormValuesForSave();
    }
  }

  private setTicadiFormValuesForSave() {
    let yearIndicator: IndicateursEtat | null = null;
    let trimestreIndicator: IndicateursEtat | null = null;

    if (this.currentTrimestre === Trimestre.T4) {
      // If the period is T4, the year period should also be validated
      const anneIndicateur = this.indicateurs.find(
        (i) =>
          (i.trimestreIndicateurs === Trimestre.Annee &&
            i.type.toUpperCase() === IndicatorType.TICADI_BA.toUpperCase()) ||
          (i.trimestreIndicateurs === Trimestre.T4 &&
            i.type.toUpperCase() === IndicatorType.BA.toUpperCase())
      );
      if (anneIndicateur) {
        yearIndicator = this.getIndicatorDetailsFromForm(true, anneIndicateur);
      }
    }

    const indicateur = this.indicateurs.find(
      (i) =>
        i.trimestreIndicateurs === this.currentTrimestre &&
        (i.type.toUpperCase() === IndicatorType.TICADI_BA ||
          i.type === IndicatorType.BA)
    );

    if (indicateur) {
      trimestreIndicator = this.getIndicatorDetailsFromForm(true, indicateur);
    }

    if (yearIndicator || trimestreIndicator) {
      this.store$.dispatch(
        setTicadiIndicatorsValuesToUpdate({
          anneeIndicateur: yearIndicator,
          trimestreIndicateur: trimestreIndicator,
        })
      );
    }
  }

  private setFormValuesForSave() {
    const curIndicateur = this.indicateurs.find(
      (i) =>
        i.trimestreIndicateurs.toLowerCase() ===
        this.currentTrimestre.toLowerCase()
    );

    const indicateur = this.getIndicatorDetailsFromForm(false, curIndicateur);

    if (indicateur) {
      this.store$.dispatch(setIndicatorToUpdate({ indicateur }));
    }
  }

  private getIndicatorDetailsFromForm(
    isTicadi: boolean,
    curIndicateur?: IndicateursEtat
  ): IndicateursEtat | null {
    if (!curIndicateur) {
      return null;
    }
    const indicateur = R.clone(curIndicateur);

    if (
      indicateur?.trimestreIndicateurs === Trimestre.Annee ||
      (indicateur?.trimestreIndicateurs === Trimestre.T4 && !isTicadi)
    ) {
      indicateur.indVolumeAnnee.poidsNetT =
        this.model?.volumesDistributed?.annee;

      this.incorrectValues[`beneficiariesHouseholds.annee`] = !Number.isInteger(
        this.model?.beneficiariesHouseholds?.annee
      );
      indicateur.indBeneficiairesAnnee.nbFoyersInscrits =
        this.model?.beneficiariesHouseholds?.annee;

      this.incorrectValues[`beneficiariesRights.annee`] = !Number.isInteger(
        this.model?.beneficiariesRights?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnesInscrites =
        this.model?.beneficiariesRights?.annee;

      this.incorrectValues[`beneficiariesPassages.annee`] = !Number.isInteger(
        this.model?.beneficiariesPassages?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnesAidees =
        this.model?.beneficiariesPassages?.annee;

      this.incorrectValues[`beneficiariesAge0to17.annee`] = !Number.isInteger(
        this.model?.beneficiariesAge0to17?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnes0a17ans =
        this.model?.beneficiariesAge0to17?.annee;

      this.incorrectValues[`beneficiariesAge18to29.annee`] = !Number.isInteger(
        this.model?.beneficiariesAge18to29?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnes18a29ans =
        this.model?.beneficiariesAge18to29?.annee;

      this.incorrectValues[`beneficiariesAge30to64.annee`] = !Number.isInteger(
        this.model?.beneficiariesAge30to64?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnes30a64ans =
        this.model?.beneficiariesAge30to64?.annee;

      this.incorrectValues[`beneficiariesAgeOver65Fse.annee`] =
        !Number.isInteger(this.model?.beneficiariesAgeOver65Fse?.annee);
      indicateur.indBeneficiairesAnnee.nbPersonnesPlus65ansFse =
        this.model?.beneficiariesAgeOver65Fse?.annee;

      this.incorrectValues[`beneficiariesAge0to3.annee`] = !Number.isInteger(
        this.model?.beneficiariesAge0to3?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnes0a3ans =
        this.model?.beneficiariesAge0to3?.annee;

      this.incorrectValues[`beneficiariesAge4to14.annee`] = !Number.isInteger(
        this.model?.beneficiariesAge4to14?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnes4a14ans =
        this.model?.beneficiariesAge4to14?.annee;

      this.incorrectValues[`beneficiariesAge15to25.annee`] = !Number.isInteger(
        this.model?.beneficiariesAge15to25?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnes15a25ans =
        this.model?.beneficiariesAge15to25?.annee;

      this.incorrectValues[`beneficiariesAge26to64.annee`] = !Number.isInteger(
        this.model?.beneficiariesAge26to64?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnes26a64ans =
        this.model?.beneficiariesAge26to64?.annee;

      this.incorrectValues[`beneficiariesAgeOver65.annee`] = !Number.isInteger(
        this.model?.beneficiariesAgeOver65?.annee
      );
      indicateur.indBeneficiairesAnnee.nbPersonnesPlus65ans =
        this.model?.beneficiariesAgeOver65?.annee;

      this.incorrectValues[`beneficiariesSexMale.annee`] = !Number.isInteger(
        this.model?.beneficiariesSexMale?.annee
      );
      indicateur.indBeneficiairesAnnee.nbHommes =
        this.model?.beneficiariesSexMale?.annee;

      this.incorrectValues[`beneficiariesSexFemale.annee`] = !Number.isInteger(
        this.model?.beneficiariesSexFemale?.annee
      );
      indicateur.indBeneficiairesAnnee.nbFemmes =
        this.model?.beneficiariesSexFemale?.annee;
    }
    if (indicateur?.trimestreIndicateurs !== Trimestre.Annee) {
      indicateur.indVolumeTrimestre.poidsNetT = this.getTrimestreVolDistributed(
        indicateur.trimestreIndicateurs
      );
      indicateur.indBeneficiairesTrimestre.nbFoyersInscrits =
        this.getTrimestreNbFoyersInscrits(indicateur.trimestreIndicateurs);
      indicateur.indBeneficiairesTrimestre.nbPersonnesInscrites =
        this.getTrimestreNbPersonnesInscrites(indicateur.trimestreIndicateurs);
      indicateur.indBeneficiairesTrimestre.nbPersonnesAidees =
        this.getTrimestreNbPersonnesAidees(indicateur.trimestreIndicateurs);
    }

    indicateur.associationCommentaire[indicateur.annee] = this.comments;

    return indicateur;
  }

  private getTrimestreVolDistributed(trimestre: Trimestre): number {
    switch (trimestre) {
      case Trimestre.T1: {
        return this.model?.volumesDistributed?.t1;
      }
      case Trimestre.T2: {
        return this.model?.volumesDistributed?.t2;
      }
      case Trimestre.T3: {
        return this.model?.volumesDistributed?.t3;
      }
      case Trimestre.T4: {
        return this.model?.volumesDistributed?.t4;
      }
      default: {
        return 0;
      }
    }
  }

  private getTrimestreNbPersonnesInscrites(trimestre: Trimestre): number {
    switch (trimestre) {
      case Trimestre.T1: {
        this.incorrectValues[`beneficiariesRights.t1`] = !Number.isInteger(
          this.model?.beneficiariesRights?.t1
        );
        return this.model?.beneficiariesRights?.t1;
      }
      case Trimestre.T2: {
        this.incorrectValues[`beneficiariesRights.t2`] = !Number.isInteger(
          this.model?.beneficiariesRights?.t2
        );
        return this.model?.beneficiariesRights?.t2;
      }
      case Trimestre.T3: {
        this.incorrectValues[`beneficiariesRights.t3`] = !Number.isInteger(
          this.model?.beneficiariesRights?.t3
        );
        return this.model?.beneficiariesRights?.t3;
      }
      case Trimestre.T4: {
        this.incorrectValues[`beneficiariesRights.t4`] = !Number.isInteger(
          this.model?.beneficiariesRights?.t4
        );
        return this.model?.beneficiariesRights?.t4;
      }
      default: {
        return 0;
      }
    }
  }

  private getTrimestreNbFoyersInscrits(trimestre: Trimestre): number {
    switch (trimestre) {
      case Trimestre.T1: {
        this.incorrectValues[`beneficiariesHouseholds.t1`] = !Number.isInteger(
          this.model?.beneficiariesHouseholds?.t1
        );
        return this.model?.beneficiariesHouseholds?.t1;
      }
      case Trimestre.T2: {
        this.incorrectValues[`beneficiariesHouseholds.t2`] = !Number.isInteger(
          this.model?.beneficiariesHouseholds?.t2
        );
        return this.model?.beneficiariesHouseholds?.t2;
      }
      case Trimestre.T3: {
        this.incorrectValues[`beneficiariesHouseholds.t3`] = !Number.isInteger(
          this.model?.beneficiariesHouseholds?.t3
        );
        return this.model?.beneficiariesHouseholds?.t3;
      }
      case Trimestre.T4: {
        this.incorrectValues[`beneficiariesHouseholds.t4`] = !Number.isInteger(
          this.model?.beneficiariesHouseholds?.t4
        );
        return this.model?.beneficiariesHouseholds?.t4;
      }
      default: {
        return 0;
      }
    }
  }

  private getTrimestreNbPersonnesAidees(trimestre: Trimestre): number {
    switch (trimestre) {
      case Trimestre.T1: {
        this.incorrectValues[`beneficiariesPassages.t1`] = !Number.isInteger(
          this.model?.beneficiariesPassages?.t1
        );
        return this.model?.beneficiariesPassages?.t1;
      }
      case Trimestre.T2: {
        this.incorrectValues[`beneficiariesPassages.t2`] = !Number.isInteger(
          this.model?.beneficiariesPassages?.t2
        );
        return this.model?.beneficiariesPassages?.t2;
      }
      case Trimestre.T3: {
        this.incorrectValues[`beneficiariesPassages.t3`] = !Number.isInteger(
          this.model?.beneficiariesPassages?.t3
        );
        return this.model?.beneficiariesPassages?.t3;
      }
      case Trimestre.T4: {
        this.incorrectValues[`beneficiariesPassages.t4`] = !Number.isInteger(
          this.model?.beneficiariesPassages?.t4
        );
        return this.model?.beneficiariesPassages?.t4;
      }
      default: {
        return 0;
      }
    }
  }

  public onSeeTrimestreInitialValues(trimestre: Trimestre, isEdit: boolean) {
    this.toggleIndicatorInitialDetails.emit({
      indicators: this.indicateurs,
      selectedTrimestre: trimestre,
      isEditMode: isEdit,
    });
  }
}
