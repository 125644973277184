import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  FilterTypesEnum,
  ListOrder,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartComponent,
  TcSmartFilterConfig,
  TcSmartListConfig,
} from '@tc/abstract';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  formlyColumn,
  formlyControl,
  formlyRow,
  getTcFilters,
  TcFormlyComponent,
  TcFormlyWrapper,
  TcTranslateService,
} from '@tc/core';
import { initTcListDataStore, NgRxTcDataState } from '@tc/data-store';
import { selectByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';

import { AmsRouteProvider } from '../../../../services/ams-route.provider';
import { AssociationCategorie, NiveauParteneriatAssociation } from '../../../associations/types';
import { Statistiques } from '../../interfaces/statistiques.interface';
import { loadStatistiquesData } from '../../store/statistiques.actions';
import { getSelectedYear, getStatistiquesData } from '../../store/statistiques.selectors';
import { StatistiquesIndicatorType } from '../../types/statistiques-indicator-type.enum';
import { getAuthenticatedUser } from '../../../../../modules/auth/store/auth.selectors';
import { getBankIdFromUserDetails } from '../../../../shared/utils/user.utils';

@Component({
  selector: 'app-statistiques-grid',
  templateUrl: './statistiques-grid.component.html',
  styleUrls: ['./statistiques-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatistiquesGridComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  subscription: Subscription = new Subscription();
  statistiquesData: Statistiques[] = [];
  selectedYear: number = new Date().getFullYear();

  nbAssocViaOutil: Statistiques;
  nbAssocAyant: Statistiques;
  nbAssocIndValides: Statistiques;
  nbAssociationsIndNonRecus: Statistiques;

  storeKey = 'statistiques-grid';
  filterConfig: TcSmartFilterConfig;
  listConfig: TcSmartListConfig;
  fields: FormlyFieldConfig[];

  private filterStore$: Observable<NgRxTcDataState>;

  constructor(
    store$: Store<any>,
    private readonly translate: TcTranslateService,
    private readonly routeProvider: AmsRouteProvider
  ) {
    super(store$);

    this.filterStore$ = store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  async ngOnInit() {
    const selectedYear$ = this.store$
      .select(getSelectedYear)
      .subscribe((selectedYear) => {
        this.selectedYear = selectedYear;
      });
    this.subscription.add(selectedYear$);

    const authenticatedUser = await this.store$
    .select(getAuthenticatedUser)
    .pipe(take(1))
    .toPromise();

    // Authenticated user contains extra details that we require if the user is a bank
    // to set the default bank on the bank filter and disable the control.
    // For admin, the control is enabled and all banks are present in the control.
    this.setFilterConfig(authenticatedUser?.additionalDetails);

    const statistiquesData$ = this.store$
      .select(getStatistiquesData)
      .subscribe((data) => {
        this.statistiquesData = data;

        this.nbAssocViaOutil = data?.filter(
          (x) => x.indicatorType === StatistiquesIndicatorType.NbAssocViaOutil
        )[0];
        this.nbAssocAyant = data?.filter(
          (x) => x.indicatorType === StatistiquesIndicatorType.NbAssocAyant
        )[0];
        this.nbAssocIndValides = data?.filter(
          (x) => x.indicatorType === StatistiquesIndicatorType.NbAssocIndValides
        )[0];
        this.nbAssociationsIndNonRecus = data?.filter(
          (x) =>
            x.indicatorType ===
            StatistiquesIndicatorType.NbAssociationsIndNonRecus
        )[0];
      });
    this.subscription.add(statistiquesData$);

    // Subscription to update Statistiques tables
    const statsSub = selectByKey(
      getTcFilters,
      this.filterStore$,
      this.storeKey
    ).subscribe((curFilter) => {
      this.store$.dispatch(
        loadStatistiquesData({ statistiquesFilter: curFilter })
      );
    });

    this.subscription.add(statsSub);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private setFilterConfig(userDetails: { [key: string]: any }) {
    // If the logged in user is a bank, we need to set the default selected value to the bank
    // and disable the select form
    const bankId = getBankIdFromUserDetails(userDetails) ?? "";
    this.fields = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'annee',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: new Date().getFullYear(),
                templateOptions: {
                  clearButtonEnabled: false,
                  options: this.getAnneeOption(),
                },
                colSpan: 1,
              }),
              formlyControl({
                key: 'banqueId',
                type: TcFormlyComponent.TcSmartSelect,
                defaultValue: bankId,
                templateOptions: {
                  subscriptSizing: 'dynamic',
                  disabled: !!bankId,
                  clearButtonEnabled: false,
                  filterType: FilterTypesEnum.Equal,
                  multiple: false,
                  labelFieldName: 'nom',
                  valueFieldName: 'id',
                  defaultValue: '',
                  defaultValueLabel: this.translate.instant(
                    `${this.storeKey}.filter.values.banqueId.allBanques`
                  ),
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.RestApi,
                    dataSet: this.routeProvider.getRoutes().banques,
                    sortOrder: {
                      key: 'nom',
                      order: ListOrder.Asc,
                    },
                  },
                },
                lgColSpan: 3,
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'categorie',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: -1,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: -1,
                      label: this.translate.instant(
                        `associationCategorie.tousCategories`
                      ),
                    },
                    {
                      value: "0",
                      label: this.translate.instant(
                        `associationCategorie.${AssociationCategorie.Categorie1}`
                      ),
                    },
                    {
                      value: 1,
                      label: this.translate.instant(
                        `associationCategorie.${AssociationCategorie.Categorie2}`
                      ),
                    },
                  ],
                },
                colSpan: 2,
              }),
              formlyControl({
                key: 'niveauParteneriatNouveau',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: -1,
                colSpan: 4,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: -1,
                      label: this.translate.instant('globalLabels.tous')
                    },
                    {
                      value: NiveauParteneriatAssociation.Aucun,
                      label: this.translate.instant('associations-niveau-parteneriat.aucun')
                    },
                    {
                      value: NiveauParteneriatAssociation.TicadiSeul,
                      label: this.translate.instant('associations-niveau-parteneriat.ticadi-seul')
                    },
                    {
                      value: NiveauParteneriatAssociation.ProxidonSeul,
                      label: this.translate.instant('associations-niveau-parteneriat.proxidon-seul')
                    },
                    ,
                    {
                      value: NiveauParteneriatAssociation.ProxidonAndTicadi,
                      label: this.translate.instant('associations-niveau-parteneriat.proxidon-and-ticadi')
                    },
                    ,
                    {
                      value: NiveauParteneriatAssociation.CompteProxidonDeTest,
                      label: this.translate.instant('associations-niveau-parteneriat.compte-proxidon-de-test')
                    },
                    ,
                    {
                      value: NiveauParteneriatAssociation.CompteProxidonBAAggisantCommeUneAssociation,
                      label: this.translate.instant('associations-niveau-parteneriat.compte-proxidon-ba-aggisant-comme-une-association')
                    },
                    ,
                    {
                      value: NiveauParteneriatAssociation.PacobaSeul,
                      label: this.translate.instant('associations-niveau-parteneriat.pacoba-seul')
                    },
                    ,
                    {
                      value: NiveauParteneriatAssociation.PacobaAndTicadi,
                      label: this.translate.instant('associations-niveau-parteneriat.pacoba-and-ticadi')
                    },
                    ,
                    {
                      value: NiveauParteneriatAssociation.PacobaAndTicadiAndProxidon,
                      label: this.translate.instant('associations-niveau-parteneriat.pacoba-and-ticadi-and-proxidon')
                    },
                    ,
                    {
                      value: NiveauParteneriatAssociation.PacobaAndProxidon,
                      label: this.translate.instant('associations-niveau-parteneriat.pacoba-and-proxidon')
                    }
                  ]
                }
              }),
            ],
          }),
        ],
        colSpan: 12,
        wrappers: [TcFormlyWrapper.ExpansionPanel],
        templateOptions: {
          label: 'filter-options',
          displayWrapperOnlyOnMobile: true,
        },
      }),
    ];

    this.filterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      fields: this.fields,
      isPersistant: false,
    };

    this.listConfig = {
      configType: TcConfigTypes.TcList,
      storeKey: this.storeKey,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.RestApi,
        dataSet: this.routeProvider.getRoutes().nbAssocViaOutilTrimestre,
        fields: '',
      },
      filterConfig: this.filterConfig,
    };

    this.store$.dispatch(
      initTcListDataStore({
        storeKey: this.storeKey,
        listConfig: this.listConfig,
      })
    );
  }

  getAnneeOption(): { value: number; label: string }[] {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    return [
      { value: currentYear, label: currentYear.toString() },
      { value: previousYear, label: previousYear.toString() },
    ];
  }
}
