import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import {
  GridApi,
  IAfterGuiAttachedParams,
  ICellRendererParams,
} from 'ag-grid-community';
import { FormBuilder, FormGroup } from '@angular/forms';

export interface ComboRendererOption {
  label: string;
  value: string;
}

@Component({
  selector: 'tc-grid-combo-renderer',
  templateUrl: './tc-grid-combo-renderer.component.html',
  styleUrls: ['./tc-grid-combo-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcGridComboRendererComponent implements AgRendererComponent {
  @ViewChild('input') input: ElementRef;

  options: ComboRendererOption[] = [];
  form: FormGroup;
  gridApi: GridApi;
  rowIndex: number;
  colKey: string;
  disabled: boolean = false;

  constructor(private readonly _fb: FormBuilder) {}

  refresh(params: any): boolean {
    return false;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  public agInit(params: ICellRendererParams): void {
    this.options = params['possibleValues'];
    this.gridApi = params.api;
    this.colKey = params.colDef.field;
    this.rowIndex = params.rowIndex;
    this.disabled = params['disabled'];

    this.form = this._fb.group({
      value: params.value,
    });

    if (this.disabled) {
      this.form.disable();
    }

    this.form.valueChanges.subscribe({
      next: (value) => {
        let rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);
        if (rowNode) {
          rowNode.setDataValue(params.colDef.field, value.value);
          params.api.refreshCells({ rowNodes: [rowNode] });
        }
      },
    });
  }
}
