import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-tc-formly-input',
  templateUrl: './tc-formly-input.component.html',
  styleUrls: ['./tc-formly-input.component.scss'],
})
export class TcFormlyInputComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('tcInput') tcInput: MatInput;

  formControlSubscription: Subscription;

  ngAfterViewInit(): void {

    this.props['inputCleared$']?.subscribe((_: Symbol) => {
      this.formControl.reset();
      this.tcInput.focus();
    });
  }

  ngOnInit(): void {

    if (this.props.type === 'email') {
      // I could not find online exactly which are the common email copy start substrings.
      // It might depend on from where the user is copying the email
      // The only one I am 100% certain of it "mailto:", because it's used in html for emailing.
      // So I added a some others just to be sure.
      const emailCopyCommonStartSubstrings = [
        'mailto://',
        'mailto:',
        'mailto//:',
        'mailto//',
        '//',
      ];

      const emailCopyCommonStartEndCharacters = [
        // OSC-71: Common pair for emails copied form Outlook
        {
          start: '<',
          end: '>',
        },
      ];

      // Trim email's white spaces if the initial value has any
      if (
        typeof this.formControl.value === 'string' &&
        (this.formControl.value.startsWith(' ') ||
          this.formControl.value.endsWith(' '))
      ) {
        const formatedValue = this.formControl.value.trim();

        // NOTE: we need a timeout here in order to avoid expresion changed after check error.
        setTimeout(() => {
          // NOTE: DO NOT set { emitEvent: false } here, otherwise the store will not get updated.
          this.formControl.setValue(formatedValue);
        });
      }

      // Remove any of the emailCopyCommonStartSubstrings the email might contain
      this.formControlSubscription = this.formControl.valueChanges.subscribe(
        (value) => {
          if (typeof value !== 'string') return;

          // Email fields get validated on the backend and if we send a empty string value
          // it will result in a validation error
          if (value === '') {
            this.formControl.setValue(null);
            return;
          }

          // Trim email's white spaces if the value has any
          if (value.startsWith(' ') || value.endsWith(' ')) {
            const formatedValue = value.trim();

            // NOTE: DO NOT set { emitEvent: false } here, otherwise the store will not get updated.
            this.formControl.setValue(formatedValue);

            return;
          }

          let skipNextSteps;

          for (let substring of emailCopyCommonStartSubstrings) {
            if (value.startsWith(substring)) {
              const formatedValue = value.split(substring)[1];

              // NOTE: DO NOT set { emitEvent: false } here, otherwise the store will not get updated.
              this.formControl.setValue(formatedValue);

              skipNextSteps = true;
              break;
            }
          }

          if (skipNextSteps) return;

          for (let pair of emailCopyCommonStartEndCharacters) {
            const startPosition = value.indexOf(pair.start);
            const endPosition = value.indexOf(pair.end);

            if (
              startPosition >= 0 &&
              endPosition > 0 &&
              startPosition < endPosition
            ) {
              const formatedValue = value.substring(
                startPosition + 1,
                endPosition
              );

              // NOTE: DO NOT set { emitEvent: false } here, otherwise the store will not get updated.
              this.formControl.setValue(formatedValue);

              break;
            }
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.formControlSubscription?.unsubscribe();
  }
}
