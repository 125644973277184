import { Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-delivery-notes-filter',
  templateUrl: './delivery-notes-filter.component.html',
  styleUrls: ['./delivery-notes-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryNotesFilterComponent
  extends TcFormComponent<any>
{
  gridStoreKey = 'delivery-notes-grid';

  constructor(translate: TcTranslateService, elem: ElementRef) {
    super(translate, elem);
  }
}
