import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule, TcCoreModule } from '@tc/core';
import { NgxMaskModule } from 'ngx-mask';
import { AmsInfoBlockComponent } from './components/ams-info-block/ams-info-block.component';
import { AmsStatsTableComponent } from './components/ams-stats-table/ams-stats-table.component';
import { BanksService } from './services/banks.service';
import { AmsTableInputEditorComponent } from './components/ams-table-input-editor/ams-table-input-editor.component';
import { RestrictSpecialCharactersDirective } from './directives/restrict-special-characters.directive';

const components = [
  AmsInfoBlockComponent,
  AmsStatsTableComponent,
  AmsTableInputEditorComponent,
];

const directives = [RestrictSpecialCharactersDirective];

const services = [
  BanksService,
  DecimalPipe
];

@NgModule({
  imports: [
    CommonModule,
    TcCoreModule,
    MaterialModule,
    NgxMaskModule.forRoot({}),
  ],
  providers: [...services],  
  declarations: [...components, ...directives],
  exports: [...components, ...directives, CommonModule, FormsModule, TcCoreModule],
})
export class SharedModule {}
