import { TcFilterItem } from '@tc/abstract';
import { TcTranslateService } from '@tc/core';

import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';
import { Bank } from '../../../shared/typings/bank';
import { Association, NiveauParteneriatAssociationToString } from '../types';
import moment from 'moment';

interface FilterPropertyWithValue {
  field: string;
  value: string;
}

const MARGIN_BETWEEN_SECTIONS = 20;
const TABLE_FONT_SIZE = 8;
const CELL_FONT_SIZE = 6;
const TABLE_BACKGROUND_COLOR = '#9bb2d4';
const TABLE_BORDER_COLOR = '#5981b8';
const ASSOCIATIONS_TABLE_CELL_COLOR = '#dde5f0';

export const createAssociationsPDF = (
  bank: Bank | null,
  filters: TcFilterItem[],
  softwareData: AmsStatsInterface[],
  categoriesData: AmsStatsInterface[],
  associations: object[],
  translationService: TcTranslateService
) => {
  const headerContent = getHeaderContent(translationService);
  const filtersContent = createPDFFilters(bank, filters, translationService);

  return [
    { ...headerContent },
    {
      alignment: 'justify',
      columns: [
        { ...filtersContent },
        { ...getExportDateContent(translationService) },
      ],
    },
    { ...getStatsContent(softwareData, categoriesData, translationService) },
    ...getAssociationsContent(associations, translationService),
  ];
};

const getHeaderContent = (translationService: TcTranslateService) => {
  return {
    text: translationService.instant('associations-page.title'),
    bold: true,
    style: 'header',
    alignment: 'center',
    margin: [0, 0, 0, MARGIN_BETWEEN_SECTIONS],
  };
};

const getExportDateContent = (translationService: TcTranslateService) => {
  return {
    text: `${translationService.instant(
      'associations-grid.pdfExport.exportDate'
    )} ${moment(new Date()).format('DD/MM/YYYY')}`,
    bold: true,
    alignment: 'right',
  };
};

/**
 * Creates the PDF content associated with the filters part.
 * @param bank The selected bank
 * @param filters The TcFilterItem lists from the store
 * @param translationService The translation service used to translate the filter values
 * @returns The PDF content associated with the filters
 */
export const createPDFFilters = (
  bank: Bank | null,
  filters: TcFilterItem[],
  translationService: TcTranslateService
) => {
  const parsedFilters: FilterPropertyWithValue[] = [];

  parsedFilters.push(getBankFilter(bank, translationService));
  parsedFilters.push(getCategoryFilter(filters, translationService));
  parsedFilters.push(getActiveFilter(filters, translationService));

  parsedFilters.push(getCurrentParnershipLevel(filters, translationService));
  parsedFilters.push(getNewPartnershipLevel(filters, translationService));

  const pdfValue = {
    type: 'none',
    ul: [],
  };

  for (const parsedFilter of parsedFilters) {
    pdfValue.ul.push({
      text: `${parsedFilter.field}: ${parsedFilter.value}`,
      bold: true,
    });
  }
  return pdfValue;
};

const getBankFilter = (
  bank: Bank | null,
  translationService: TcTranslateService
) => {
  const localizedFieldName = translationService.instant(
    'associations-grid.pdfExport.filters.banque'
  );
  if (bank) {
    return {
      field: localizedFieldName,
      value: bank.nom,
    };
  }
  return {
    field: localizedFieldName,
    value: translationService.instant(
      'associations-grid.filter.values.banqueId.allBanques'
    ),
  };
};

const getCategoryFilter = (
  filters: TcFilterItem[],
  translationService: TcTranslateService
) => {
  const filter = filters.find((f) => f.key === 'categorie');
  const localizedFieldName = translationService.instant(
    'associations-grid.pdfExport.filters.categorie'
  );
  if (filter && filter.value) {
    return {
      field: localizedFieldName,
      value: translationService.instant(
        `associations-grid.table.values.categorie.${filter.value}`
      ),
    };
  }
  return {
    field: localizedFieldName,
    value: translationService.instant(
      'associations-grid.filter.categorie.values.tousCategories'
    ),
  };
};

const getActiveFilter = (
  filters: TcFilterItem[],
  translationService: TcTranslateService
) => {
  const filter = filters.find((f) => f.key === 'active');
  const localizedFieldName = translationService.instant(
    'associations-grid.pdfExport.filters.active'
  );
  if (filter && filter.value !== '') {
    const localizationKey = filter.value ? 'oui' : 'non';
    return {
      field: localizedFieldName,
      value: translationService.instant(
        `associations-grid.filter.active.values.${localizationKey}`
      ),
    };
  }
  return {
    field: localizedFieldName,
    value: translationService.instant(
      'associations-grid.filter.active.values.tous'
    ),
  };
};

const getCurrentParnershipLevel = (
  filters: TcFilterItem[],
  translationService: TcTranslateService
) => {
  const filter = filters.find(f => f.key === 'niveauParteneriatActuel');
  const localizedFieldName = translationService.instant(
    `associations-grid.pdfExport.filters.niveauParteneriatActuel`
  );

  if (filter && "" + filter.value !== "-1") {
    return {
      field: localizedFieldName,
      value: translationService.instant(
        `associations-grid.filter.niveau-parteneriat.values.${NiveauParteneriatAssociationToString[filter.value]}`
      ),
    };
  }
  return {
    field: localizedFieldName,
    value: translationService.instant(
      'associations-grid.filter.niveau-parteneriat.values.tous'
    ),
  };
}

const getNewPartnershipLevel = (
  filters: TcFilterItem[],
  translationService: TcTranslateService
) => {
  const filter = filters.find(f => f.key === 'niveauParteneriatNouveau');
  const localizedFieldName = translationService.instant(
    `associations-grid.pdfExport.filters.niveauParteneriatNouveau`
  );

  if (filter && "" + filter.value !== "-1") {
    return {
      field: localizedFieldName,
      value: translationService.instant(
        `associations-grid.filter.niveau-parteneriat.values.${NiveauParteneriatAssociationToString[filter.value]}`
      ),
    };
  }
  return {
    field: localizedFieldName,
    value: translationService.instant(
      'associations-grid.filter.niveau-parteneriat.values.tous'
    ),
  };
}

const getStatsContent = (
  softwareData: AmsStatsInterface[],
  categoriesData: AmsStatsInterface[],
  translationService: TcTranslateService
) => {
  const softwareDataContent = softwareData.map((data) => {
    return [
      {
        text: translationService.instant(data.label),
        borderColor: getTableBorderColor(),
      },
      {
        alignment: 'center',
        text: data.value,
        borderColor: getTableBorderColor(),
      },
    ];
  });
  const categoriesDataContent = categoriesData.map((data) => {
    return [
      {
        text: translationService.instant(data.label),
        borderColor: getTableBorderColor(),
      },
      {
        alignment: 'center',
        text: data.value,
        borderColor: getTableBorderColor(),
      },
    ];
  });
  return {
    fontSize: TABLE_FONT_SIZE,
    bold: true,
    margin: [MARGIN_BETWEEN_SECTIONS, MARGIN_BETWEEN_SECTIONS],
    table: {
      widths: ['*', 100],
      body: [...categoriesDataContent, ...softwareDataContent],
    },
    layout: {
      fillColor: TABLE_BACKGROUND_COLOR,
      fillOpacity: function (rowIndex: number) {
        if (rowIndex % 2 === 0) {
          return 0;
        }
        return 1;
      },
    },
  };
};

const getTableBorderColor = () => [
  TABLE_BORDER_COLOR,
  TABLE_BORDER_COLOR,
  TABLE_BORDER_COLOR,
  TABLE_BORDER_COLOR,
];

const getAssociationsContent = (
  associations: object[],
  translationService: TcTranslateService
) => {
  return [
    {
      table: {
        headerRows: 1,
        body: [
          [
            // Header
            {
              ...getTableHeaderCell(
                translationService.instant(
                  'associations-grid.header.code.short'
                ),
                true
              ),
            },
            {
              ...getTableHeaderCell(
                translationService.instant('associations-grid.header.nom'),
                true
              ),
            },
            {
              ...getTableHeaderCell(
                translationService.instant(
                  'associations-grid.header.banqueNom'
                ),
                true
              ),
            },
            {
              ...getTableHeaderCell(
                translationService.instant(
                  'associations-grid.header.categorie'
                ),
                true
              ),
            },
            {
              ...getTableHeaderCell(
                translationService.instant(
                  'associations-grid.header.decryptedPassword'
                ),
                true
              ),
            },
            {
              ...getTableHeaderCell(
                translationService.instant('associations-grid.header.active'),
                true
              ),
            },
            {
              ...getTableHeaderCell(
                translationService.instant(
                  'associations-grid.header.numeroVersionDisplayed'
                ),
                true
              ),
            },
            {
              ...getTableHeaderCell(
                translationService.instant('associations-grid.header.niveauParteneriatActuel'),
                true
              ),
            },
            {
              ...getTableHeaderCell(
                translationService.instant('associations-grid.header.niveauParteneriatNouveau'),
                true
              ),
            },
          ],
          ...associations.map((a) =>
            getSingleAssociationRow(a as Association, translationService)
          ),
        ],
      },
      layout: {
        fillColor: ASSOCIATIONS_TABLE_CELL_COLOR,
        fillOpacity: function (rowIndex: number) {
          if (rowIndex % 2 === 0) {
            return 0;
          }
          return 1;
        },
      },
    },
    {
      text: translationService.instant(
        'associations-grid.pdfExport.tableFooter'
      ),
      fontSize: TABLE_FONT_SIZE,
    },
  ];
};

const getTableHeaderCell = (label: string, isHeader = false) => ({
  text: label,
  alignment: 'center',
  bold: isHeader,
  fontSize: isHeader ? TABLE_FONT_SIZE : CELL_FONT_SIZE,
  borderColor: getTableBorderColor(),
});

const getSingleAssociationRow = (
  association: Association,
  translationService: TcTranslateService
): object[] => {
  const activeLocalizationKey = association.active ? 'oui' : 'non';
  const niveauParteneriatActuelKey = association.niveauParteneriatActuel;
  const niveauParteneriatActuelLabel = niveauParteneriatActuelKey !== -1
    ? translationService.instant(
        `associations-grid.filter.niveau-parteneriat.values.${NiveauParteneriatAssociationToString[niveauParteneriatActuelKey]}`
      )
    : '';

  const niveauParteneriatNouveauKey = association.niveauParteneriatNouveau;
  const niveauParteneriatNouveauLabel = niveauParteneriatNouveauKey !== -1
    ? translationService.instant(
        `associations-grid.filter.niveau-parteneriat.values.${NiveauParteneriatAssociationToString[niveauParteneriatNouveauKey]}`
      )
    : '';

  return [
    getTableHeaderCell(association.code.toString()),
    getTableHeaderCell(association.nom),
    getTableHeaderCell(association.banqueNom),
    getTableHeaderCell(
      translationService.instant(
        `associations-grid.table.values.categorie.${association.categorie}`
      )
    ),
    getTableHeaderCell(association.decryptedPassword),
    getTableHeaderCell(
      translationService.instant(
        `associations-grid.filter.active.values.${activeLocalizationKey}`
      )
    ),
    getTableHeaderCell(association.numeroVersionDisplayed),
    getTableHeaderCell(niveauParteneriatActuelLabel),
    getTableHeaderCell(niveauParteneriatNouveauLabel),
  ];
};
