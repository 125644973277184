import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPageState } from '@tc/store';
import { MaterialColor } from '@tc/abstract';
import {
  getAssociationsCategoriesData,
  getAssociationsSoftwareData,
} from '../../modules/associations/store/associations.selectors';
import {
  exportAssociationsListCsv,
  exportAssociationsListPdf,
} from '../../modules/associations/store/associations.actions';
import { AmsPage } from '../../shared/directives/ams-page';
import { getAbilities } from '@tc/permissions';
import { take } from 'rxjs/operators';
import { PermissionAction } from '../../typings/permission-action.enum';
import { PermissionSubject } from '../../typings/permission-subject.enum';

@Component({
  selector: 'app-associations-page',
  templateUrl: './associations-page.component.html',
  styleUrls: ['./associations-page.component.scss'],
})
export class AssociationsPageComponent extends AmsPage implements OnInit {
  public categoriesStatsSelector;
  public softwareStatsSelector;

  constructor(store: Store<any>) {
    super(store);
  }

  async ngOnInit() {
    this.categoriesStatsSelector = getAssociationsCategoriesData;
    this.softwareStatsSelector = getAssociationsSoftwareData;

    const abilities = await this.store
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();
    
    const buttonsList = [];

    if (abilities.can(PermissionAction.ListAssociations, PermissionSubject.All)) {
      buttonsList.push(
        {
          label: 'export-csv',
          color: MaterialColor.Primary,
          faIcon: 'fa-file-csv',
          action: () => this.store.dispatch(exportAssociationsListCsv()),
        },
      )
    }
    if (abilities.can(PermissionAction.ListAssociations, PermissionSubject.All)) {
      buttonsList.push(
        {
          label: 'export-pdf',
          color: MaterialColor.Primary,
          faIcon: 'fa-file-pdf',
          action: () => this.store.dispatch(exportAssociationsListPdf()),
        },
      )
    }

    const pageInfo: IPageState = {
      title: 'associations-page.title',
      buttonsList: buttonsList,
      displayPageTitle: true
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
